<template>
  <div class="flex space-x-2 items-center">
    <BaseAvatar
      v-if="!isForPublicReport"
      class="rounded-full"
      :class="{
        'w-6 h-6 min-w-[24px]': size === 'sm',
        'w-8 h-8 min-w-[32px]': size === 'md',
        'w-12 h-12 min-w-[48px]': size === 'lg',
      }"
      :name="getEmployeeFullName(row)"
      :src="row?.attributes?.avatar"
    />
    <div v-if="showName" class="flex flex-col leading-5 truncate">
      <span
        :title="name"
        class="font-semibold truncate"
      >
        {{ name }}
      </span>
      <span v-if="isCurrentUser(row) && !isForPublicReport" class="text-base-300 text-sm">
        {{ $t('Myself') }}
      </span>
      <span v-if="showPosition && position" class="text-base-300 text-xs">
        {{ position?.attributes?.name || '' }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { useAuthStore } from "@/modules/auth/store/authStore"
import Employee = App.Models.Employee
import Data = API.Data
import { getEmployeeFullName } from "@/modules/clients/utils/employeeUtils"
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  showPosition: {
    type: Boolean,
  },
  showName: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'sm', // sm | md | lg
  },
  isForPublicReport: {
    type: Boolean,
    default: false,
  },
})
const row = computed(() => {
  const employeeRelationship = props.data?.relationships?.employee
  if (employeeRelationship === undefined) {
    return props?.data
  }
  return employeeRelationship || {}
})

const position = computed(() => {
  return row.value?.relationships?.position
})

const { profile } = storeToRefs(useAuthStore())

function isCurrentUser(row: Data<Employee>) {
  return row.relationships?.user?.id === profile.value?.id
}

const name = computed(() => {
  const { first_name, last_name } = row?.value?.attributes || {}
  if (!first_name || !last_name) {
    return ''
  }
  return `${first_name} ${last_name}`
})
</script>
