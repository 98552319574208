<template>
  <div>
    <PageTitle v-if="!props.isProfileForm">
      <template v-if="isEdit">
        {{ employee.first_name }} {{ employee.last_name }}
      </template>
      <template v-else>
        {{ t('Create Employee') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? t('Update') : t('Create')"
      :disabled="loading || (!isEdit && !billingStore.canAddSeat)"
      @submit="createOrUpdateEmployee"
      @cancel="onCancel"
    >
      <FormCardSection :title="t('Personal Information')">
        <FormKit
          v-model="model.first_name"
          name="first_name"
          type="text"
          :label="t('First Name')"
          :placeholder="t('John')"
          validation="required"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.last_name"
          name="last_name"
          type="text"
          :label="t('Last Name')"
          :placeholder="t('Doe')"
          validation="required"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.email"
          name="email"
          :disabled="!canEditEmail"
          type="email"
          :label="t('Work Email')"
          :placeholder="t('john.doe@company.com')"
          validation="required|email"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.phone"
          name="phone"
          :label="t('Phone')"
          :placeholder="t('0741 234 567')"
          validation="phone"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.personal_email"
          name="personal_email"
          type="email"
          :label="t('Personal Email')"
          :placeholder="t('john.doe@gmail.com')"
          validation="email"
          outer-class="col-span-6 md:col-span-2"
        />
        <div class="col-span-6 md:col-span-2"/>

        <FormKit
          v-model="model.birth_date"
          name="birth_date"
          type="customDatePicker"
          :label="t('Date of Birth')"
          :placeholder="t('10.10.1990')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.personal_identification_number"
          name="personal_identification_number"
          type="text"
          :label="t('Personal Identification Number')"
          :placeholder="t('1230110220022')"
          :help="t('Used for generating legal documents')"
          :validation-label="t('Personal Identification Number')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.identity_card_number"
          name="identity_card_number"
          type="text"
          :label="t('Identity Card Number')"
          :placeholder="t('CJ123456')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.address.street"
          name="address_street"
          type="text"
          :label="t('Home Street')"
          :placeholder="t('Startdust Way 23')"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.address.zip_code"
          name="address_zip"
          type="text"
          :label="t('Zip Code')"
          :placeholder="t('8GJ 7H8')"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.address.city"
          name="address_city"
          type="text"
          :label="t('City')"
          :placeholder="t('Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.address.state"
          name="address_state"
          type="text"
          :label="t('State')"
          :placeholder="t('Cluj')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.address.country"
          name="address_country"
          type="countrySelect"
          :label="t('Country')"
          :placeholder="t('Country')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.timezone"
          name="timezone"
          type="timezoneSelect"
          :label="t('Timezone')"
          :placeholder="t('(UTC+02:00) Athens, Bucharest')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.about"
          name="about"
          type="textarea"
          :label="t('About')"
          :placeholder="t('I’m Spencer Sharp. I live in Cluj-Napoca, where I develop the future')"
          rows="4"
          validation="length:0,255"
          outer-class="col-span-6 md:col-span-4"
        />
        <FormKit
          v-model="model.quote"
          name="quote"
          type="textarea"
          :label="t('Favorite quote')"
          :placeholder="t('A person who never made a mistake, never tried anything new. \n\nAlbert Einstein')"
          rows="4"
          validation="length:0,255"
          outer-class="col-span-6 md:col-span-2"
        />
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageEmployees')"
        :title="t('Role')"
      >
        <FormKit
          v-model="model.role_id"
          name="role"
          :disabled="isOwnEmployee && model?.id"
          type="roleSelect"
          :label="t('Role')"
          :placeholder="t('Choose role')"
          outer-class="col-span-6 md:col-span-2"
          validation="required"
        />
        <div v-if="model.role_id" class="col-span-6 md:col-span-4 md:ml-10">
          <div
            v-if="settingsStore.getRolePermissions(model.role_id)?.length > 0"
            class="text-base-300 text-sm my-4"
          >
            {{ t('Permissions') }}
          </div>
          <div
            v-for="permission in settingsStore.getRolePermissions(model.role_id)"
            :key="permission.id"
            class="mb-2 flex items-center text-sm space-x-2"
          >
            <CheckIcon class="w-4 h-4 text-primary"/>
            <span>{{ $t(permission.attributes.description) }}</span>
          </div>
        </div>
      </FormCardSection>
      <FormCardSection
        v-if="!model.id"
        :title="t('Send Invite')"
      >
        <FormKit
          v-model="model.invite_immediately"
          name="invite_immediately"
          type="checkbox"
          :label="t('Send an email invitation to the new member in order to join the company!')"
          outer-class="col-span-6 mt-4"
        />
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageEmployees')"
        :title="t('Contract information')"
      >
        <FormKit
          v-model="model.contract_type"
          name="contract_type"
          type="customSelect"
          :options="contractTypeOptions"
          :label="t('Contract Type')"
          :placeholder="t('Choose contract type')"
          :help="t('Used for generating legal documents')"
          outer-class="col-span-6 md:col-span-2"
          validation="required"
        />
        <div class="col-span-6 md:col-span-4"/>
        <template v-if="isB2BContract(model.contract_type)">
          <FormKit
            v-model="model.company_name"
            name="company_name"
            type="text"
            :label="t('Company Name')"
            :placeholder="t('Creative Development Solutions SRL')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.company_position"
            name="company_position"
            type="text"
            :label="t('Company Position')"
            :placeholder="t('Administrator')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.company_vat"
            name="company_vat"
            type="text"
            :label="t('VAT Number')"
            :placeholder="t('RO32392318')"
            outer-class="col-span-6 md:col-span-1"
          />
          <FormKit
            v-model="model.company_identifier"
            name="company_identifier"
            type="text"
            :label="t('Register No.')"
            :placeholder="t('J12/1234/2018 ')"
            outer-class="col-span-6 md:col-span-1"
          />

          <FormKit
            v-model="model.company_address.street"
            name="company_address_street"
            type="text"
            :label="t('Home Street')"
            :placeholder="t('Startdust Way 23')"
            outer-class="col-span-6 md:col-span-3"
          />

          <FormKit
            v-model="model.company_address.zip_code"
            name="company_address_zip"
            type="text"
            :label="t('Zip Code')"
            :placeholder="t('8GJ 7H8')"
            outer-class="col-span-6 md:col-span-1"
          />

          <FormKit
            v-model="model.company_address.city"
            name="company_address_city"
            type="text"
            :label="t('City')"
            :placeholder="t('Cluj-Napoca')"
            outer-class="col-span-6 md:col-span-2"
          />

          <FormKit
            v-model="model.company_address.state"
            name="company_address_state"
            type="text"
            :label="t('State')"
            :placeholder="t('Cluj')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.company_address.country"
            name="company_address_country"
            type="countrySelect"
            :label="t('Country')"
            :placeholder="t('Country')"
            outer-class="col-span-6 md:col-span-2"
          />
        </template>

        <FormKit
          v-model="model.contract_bank_name"
          name="contract_bank_name"
          type="text"
          :label="t('Bank')"
          :placeholder="t('ING Bank Cluj-Napoca')"
          :help="t('Used for generating legal documents and for salary payments')"
          outer-class="col-span-6 md:col-span-3"
        />
        <FormKit
          v-model="model.contract_bank_swift"
          name="contract_bank_swift"
          type="text"
          :label="t('Swift Code')"
          :placeholder="t('BTRLRO22XXX')"
          outer-class="col-span-6 md:col-span-1"
        />
        <FormKit
          v-model="model.contract_bank_account"
          name="contract_bank_account"
          type="text"
          :label="t('IBAN')"
          :placeholder="t('RO22 AAAA 1B31 0012 3456 7890')"
          outer-class="col-span-6 md:col-span-2"
        />
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageEmployees')"
        :title="t('Employment Details')"
      >
        <FormKit
          v-model="model.start_date"
          name="start_date"
          type="customDatePicker"
          :label="t('Working Start Date')"
          :placeholder="t('02.02.2022')"
          validation="required"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-if="isEdit"
          v-model="model.termination_date"
          name="termination_date"
          type="customDatePicker"
          :label="t('Working End Date')"
          :placeholder="t('02.02.2023')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.position_id"
          name="position"
          type="positionSelect"
          :label="t('Position')"
          :placeholder="t('Frontend Developer')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.level_id"
          name="level"
          type="levelSelect"
          :label="$t('Level')"
          :placeholder="$t('Middle')"
          outer-class="col-span-6 md:col-span-2 lg:col-span-1"
        />

        <FormKit
          v-model="model.salary_type"
          name="salary_type"
          type="customSelect"
          prefix-icon="clock"
          :options="salaryTypeOptions"
          :label="t('Salary Type')"
          :placeholder="t('Monthly or Hourly')"
          :help="t('Used for generating legal documents')"
          outer-class="col-span-6 md:col-span-2 lg:col-span-2"
        />

        <FormKit
          v-model="model.salary"
          name="salary"
          type="number"
          step="0.01"
          :label="t('Amount')"
          :placeholder="t('Salary amount')"
          validation="min:0"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.salary_currency"
          name="salary_currency"
          type="currencySelect"
          :label="t('Currency')"
          :placeholder="t('EUR')"
          outer-class="col-span-6 md:col-span-2 lg:col-span-1"
        />
        <FormKit
          v-model="model.weekly_hours_capacity"
          name="weekly_hours_capacity"
          type="text"
          :label="t('Capacity')"
          :placeholder="t('40')"
          validation="required|min:0"
          outer-class="col-span-6 md:col-span-2"
        >
          <template #suffix>
            <div class="input-suffix text-base-300">
              {{ t('h / week') }}
            </div>
          </template>
        </FormKit>
        <FormKit
          v-if="$can('manageInvoices')"
          v-model="model.billed_rate"
          name="billed_rate"
          type="text"
          :label="t('Default billable rate')"
          :placeholder="t('40')"
          :help="t('Used in reports and invoices. You can override this value in projects.')"
          validation="min:0"
          outer-class="col-span-6 md:col-span-2"
        >
          <template #suffix>
            <div class="input-suffix text-base-300">
              {{ defaultCurrency }} / {{ $t('per hour') }}
            </div>
          </template>
        </FormKit>

        <FormKit
          v-if="$can('manageInvoices')"
          v-model="model.internal_cost_hourly_rate"
          name="internal_cost_hourly_rate"
          type="text"
          :label="t('Internal cost rate')"
          :placeholder="t('40')"
          :help="t('Used in reports and invoices. Based on the provided value we calculate the margins for each project.')"
          validation="min:0"
          outer-class="col-span-6 md:col-span-2"
        >
          <template #suffix>
            <div class="input-suffix text-base-300">
              {{ defaultCurrency }} / {{ $t('per hour') }}
            </div>
          </template>
        </FormKit>
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageEmployees')"
        :title="t('Evaluations')"
      >
        <FormKit
          :model-value="t('Technical')"
          prefix-icon="cog-8-tooth"
          type="text"
          readonly
          :label="t('Evaluation Type')"
          :placeholder="t('Technical')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.technical_evaluation_assignees"
          name="technical_evaluation_assignees"
          type="employeeSelect"
          multiple
          filterable
          :label="t('Assignees')"
          :placeholder="t('John Doe')"
          outer-class="col-span-6 md:col-span-4"
        />

        <FormKit
          :model-value="t('Salary')"
          prefix-icon="banknotes"
          type="text"
          readonly
          :label="t('Evaluation Type')"
          :placeholder="t('Salary')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.salary_evaluation_assignees"
          name="salary_evaluation_assignees"
          type="employeeSelect"
          multiple
          filterable
          :label="t('Assignees')"
          :placeholder="t('John Doe')"
          outer-class="col-span-6 md:col-span-4"
        />
      </FormCardSection>
      <FormCardSection
        v-if="$can('manageEmployees')"
        :title="t('Holidays')"
      >
        <EmployeeHolidayPolicies
          :employee-id="model.id"
          :contract-type="model.contract_type"
          :start-date="!model.id ? model.start_date : undefined"
          :show-initial-balance="true"
          class="col-span-6"
        >
          <template
            v-if="model.id && !model.initial_vacation_balance !== undefined"
            #balance
          >
            <FormKit
              v-model="model.initial_vacation_balance"
              :label="$t('Balance')"
              name="vacation_balance"
              type="number"
            />
          </template>
        </EmployeeHolidayPolicies>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useHead } from '@vueuse/head'
import { useRoute, useRouter } from "vue-router"
import { CheckIcon } from "@heroicons/vue/24/outline"
import { defaultCurrency } from "../../../plugins/formatPrice"
import {
  contractTypeOptions,
  ContractTypes,
  EmployeeStatus,
  isB2BContract,
  salaryTypeOptions,
} from "@/modules/employees/utils/employeeUtils"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import EmployeeHolidayPolicies from "@/modules/employees/components/EmployeeHolidayPolicies.vue"
import PageTitle from "@/components/common/PageTitle.vue"
import FormCardSection from "@/components/form/FormCardSection.vue"
import { API_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useBillingStore } from "@/modules/settings/store/billingStore"
import { can } from "@/plugins/permissionPlugin"

const props = defineProps({
  employee: {
    type: Object,
    default: () => ({}),
  },
  loading: Boolean,
  isProfileForm: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const settingsStore = useSettingsStore()
const { isOwnEmployee } = useAuth()

const address = {
  street: '',
  country: '',
  city: '',
  state: '',
  zip_code: '',
}
const model = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  personal_email: '',
  birth_date: '',
  personal_identification_number: '',
  identity_card_number: '',
  address: {
    ...address,
  },
  company_address: {
    ...address,
  },
  timezone: '',
  invite_immediately: false,
  about: '',
  quote: '',
  contract_type: '' as ContractTypes,
  contract_bank_name: '',
  contract_bank_swift: '',
  contract_bank_account: '',
  company_name: '',
  company_position: '',
  company_vat: '',
  company_identifier: '',
  start_date: '',
  termination_date: '',
  position_id: null,
  salary_type: 'monthly',
  salary_currency: 'EUR',
  billed_rate: '',
  internal_cost_hourly_rate: '',
  salary: null,
  role_id: null,
  level_id: null,
  initial_vacation_balance: 0,
  weekly_hours_capacity: 40,
  salary_evaluation_assignees: [],
  technical_evaluation_assignees: [],
})
const loading = ref(false)

const isEdit = computed<boolean>(() => {
  return props.employee?.id !== undefined
})

const router = useRouter()
const route = useRoute()
const employeeStore = useEmployeeStore()

const canEditEmail = computed<boolean>(() => {
  if (!can('manageEmployees')) {
    return false
  }
  if (!isEdit.value) {
    return true
  }

  return isEdit.value && [EmployeeStatus.Draft, EmployeeStatus.Invited].includes(props.employee.status)
})

function getRedirectPath() {
  let redirectPath = `/employees/${props.employee.id}/details`
  if (!isEdit.value) {
    redirectPath = `/employees`
  }
  if (route.path.includes('account')) {
    redirectPath = '/settings/account/employee-profile/details'
  }
  return redirectPath
}

const billingStore = useBillingStore()

async function createOrUpdateEmployee() {
  try {
    const data: any = {
      ...model.value,
    }
    if (data.birth_date) {
      data.birth_date = formatDate(data.birth_date, API_DATE_FORMAT)
    }
    if (data.start_date) {
      data.start_date = formatDate(data.start_date, API_DATE_FORMAT)
    }
    if (data.termination_date) {
      data.termination_date = formatDate(data.termination_date, API_DATE_FORMAT)
    }
    loading.value = true
    if (isEdit.value && data.email === props.employee.email) {
      delete data.email
    }
    if (isEdit.value) {
      await employeeStore.updateEmployee(data)
    } else {
      await employeeStore.createEmployee(data)
      billingStore.onEmployeeCreated()
    }
    await router.push(getRedirectPath())
    await employeeStore.getEmployee(data.id)
    await employeeStore.getAllEmployees()
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

function initModelAddress(addressKey: 'address' | 'company_address') {
  if (!model.value[addressKey]) {
    model.value[addressKey] = {
      ...address,
    }
  } else {
    model.value[addressKey] = {
      ...address,
      ...model.value[addressKey],
    }
  }
}

function initModel(employee: any) {
  if (!employee) {
    return
  }

  model.value = {
    ...model.value,
    ...employee,
  }
  initModelAddress('address')
  initModelAddress('company_address')

  if (!model.value.technical_evaluation_assignees) {
    model.value.technical_evaluation_assignees = []
  }
  if (!model.value.salary_evaluation_assignees) {
    model.value.salary_evaluation_assignees = []
  }

  const keysToDelete = [
    'relationships',
    'meta',
  ]
  keysToDelete.forEach((key: string) => {
    delete model.value[key]
  })
}

watch(() => props.employee, (employee) => {
  initModel(employee)
}, { immediate: true })

async function onCancel() {
  await router.push(getRedirectPath())
}

useHead({
  title: isEdit.value ? t('Edit Employee') : t('Create New Employee'),
})
</script>
