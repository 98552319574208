// We need this because url search params decodes + as space
export function extractEmailFromString(inputString: string): string {
  const regex = /email=([^&]+)/;
  const match = inputString.match(regex);
  return match ? decodeURIComponent(match[1]) : '';
}

export function isUuid(value: string) {
  return value.length === 26
}
