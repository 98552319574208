import { Component } from 'vue'
import EmployeeStatusFilter from '@/components/table/filters/EmployeeStatusFilter.vue'
import HolidayStatusFilter from '@/components/table/filters/HolidayStatusFilter.vue'
import EmployeeFilter from '@/components/table/filters/EmployeeFilter.vue'
import DocumentTypeFilter from '@/components/table/filters/DocumentTypeFilter.vue'
import DocumentTagFilter from '@/components/table/filters/DocumentTagFilter.vue'
import EvaluationTypeFilter from '@/components/table/filters/EvaluationTypeFilter.vue'
import i18n from '@/i18n'
import PaidStatusFilter from '@/components/table/filters/PaidStatusFilter.vue'
import DateFilter from '@/components/table/filters/DateFilter.vue'
import YearFilter from '@/components/table/filters/YearFilter.vue'
import ClientFilter from '@/components/table/filters/ClientFilter.vue'
import ExpirationDateFilter from "@/components/table/filters/ExpirationDateFilter.vue"
import InvoiceStatusFilter from "@/components/table/filters/InvoiceStatusFilter.vue"
import PositionFilter from "@/components/table/filters/PositionFilter.vue"
import ProjectStatusFilter from "@/components/table/filters/ProjectStatusFilter.vue";

interface FilterTypesType {
  [key: string]: FilterType
}

export interface FilterType {
  component: string
  key: string
  multiple?: boolean
  filterLabel?: string
}

const FilterComponents = {
  EmployeeStatusFilter: 'EmployeeStatusFilter',
  ProjectStatusFilter: 'ProjectStatusFilter',
  HolidayStatusFilter: 'HolidayStatusFilter',
  PaidStatusFilter: 'PaidStatusFilter',
  DocumentTypeFilter: 'DocumentTypeFilter',
  PositionFilter: 'PositionFilter',
  EvaluationTypeFilter: 'EvaluationTypeFilter',
  EmployeeFilter: 'EmployeeFilter',
  ClientFilter: 'ClientFilter',
  DateFilter: 'DateFilter',
  ExpirationDateFilter: 'ExpirationDateFilter',
  InvoiceStatusFilter: 'InvoiceStatusFilter',
  YearFilter: 'YearFilter',
  DocumentTagFilter: 'DocumentTagFilter',
}
export const FilterComponentMap = {
  [FilterComponents.EmployeeStatusFilter]: EmployeeStatusFilter,
  [FilterComponents.ProjectStatusFilter]: ProjectStatusFilter,
  [FilterComponents.HolidayStatusFilter]: HolidayStatusFilter,
  [FilterComponents.PaidStatusFilter]: PaidStatusFilter,
  [FilterComponents.DocumentTypeFilter]: DocumentTypeFilter,
  [FilterComponents.DocumentTagFilter]: DocumentTagFilter,
  [FilterComponents.PositionFilter]: PositionFilter,
  [FilterComponents.EvaluationTypeFilter]: EvaluationTypeFilter,
  [FilterComponents.EmployeeFilter]: EmployeeFilter,
  [FilterComponents.ClientFilter]: ClientFilter,
  [FilterComponents.DateFilter]: DateFilter,
  [FilterComponents.ExpirationDateFilter]: ExpirationDateFilter,
  [FilterComponents.InvoiceStatusFilter]: InvoiceStatusFilter,
  [FilterComponents.YearFilter]: YearFilter,
}

export function getFilterComponent(componentName: string): Component {
  return FilterComponentMap[componentName]
}
export const FilterTypes: FilterTypesType = {
  EmployeeStatus: {
    component: FilterComponents.EmployeeStatusFilter,
    key: 'status',
    filterLabel: i18n.t('Status'),
  },
  ProjectStatus: {
    component: FilterComponents.ProjectStatusFilter,
    key: 'status',
    filterLabel: i18n.t('Status'),
  },
  HolidayStatus: {
    component: FilterComponents.HolidayStatusFilter,
    key: 'status',
    filterLabel: i18n.t('Status'),
  },
  PaidStatus: {
    component: FilterComponents.PaidStatusFilter,
    key: 'paid',
    filterLabel: i18n.t('Paid'),
  },
  DocumentType: {
    component: FilterComponents.DocumentTypeFilter,
    key: 'type_id',
    filterLabel: i18n.t('Type'),
  },
  Tags: {
    component: FilterComponents.DocumentTagFilter,
    key: 'tags',
    filterLabel: i18n.t('Tags'),
  },
  EvaluationType: {
    component: FilterComponents.EvaluationTypeFilter,
    key: 'type_id',
    filterLabel: i18n.t('Type'),
  },
  Employee: {
    component: FilterComponents.EmployeeFilter,
    key: 'employee_id',
    filterLabel: i18n.t('Employee'),
  },
  Position: {
    component: FilterComponents.PositionFilter,
    key: 'position_id',
    filterLabel: i18n.t('Position'),
  },
  Client: {
    component: FilterComponents.ClientFilter,
    key: 'client_id',
    filterLabel: i18n.t('Client'),
  },
  IssueDate: {
    component: FilterComponents.DateFilter,
    key: 'issue_date',
    filterLabel: i18n.t('Issue date'),
  },
  InvoiceStatus: {
    component: FilterComponents.InvoiceStatusFilter,
    key: 'status',
    filterLabel: i18n.t('Status'),
  },
  ExpirationDate: {
    component: FilterComponents.ExpirationDateFilter,
    key: 'end_date',
    filterLabel: i18n.t('Expiration Date'),
  },
  Assignees: {
    component: FilterComponents.EmployeeFilter,
    key: 'assignees',
    multiple: true,
    filterLabel: i18n.t('Assignees'),
  },
  StartDate: {
    component: FilterComponents.DateFilter,
    key: 'start_date',
    filterLabel: i18n.t('Start Date'),
  },
  EvaluatedAt: {
    component: FilterComponents.DateFilter,
    key: 'evaluated_at',
    filterLabel: i18n.t('Evaluated At'),
  },
  NextEvaluation: {
    component: FilterComponents.DateFilter,
    key: 'next_evaluation_at',
    filterLabel: i18n.t('Next Evaluation'),
  },
  Year: {
    component: FilterComponents.YearFilter,
    key: 'year',
    filterLabel: i18n.t('Year'),
  },
}
