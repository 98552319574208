<template>
  <EvaluationTable :employee-id="currentEmployee.id" />
</template>

<script setup lang="ts">
import EvaluationTable from "@/modules/evaluations/components/EvaluationTable.vue";
import { storeToRefs } from "pinia";
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";
const { currentEmployee } = storeToRefs(useEmployeeStore())
</script>
<route lang="yaml">
name: Employee Evaluations
</route>
