<template>
  <button
    v-bind="$attrs"
    class="btn"
    :class="[
      variantMap,
      sizeMap,
      {
        'btn-outline': outline,
        'btn-square': square,
        'btn-circle': circle,
        'btn-loading pointer-events-none': loading,
        'btn-disabled': disabled,
      },
    ]"
    :disabled="disabled"
    :type="type"
  >
    <span
      v-if="loading"
      :class="[iconSizeMap]"
      class="loading loading-spinner mr-2"
    />
    <component
      :is="leftIcon"
      v-if="leftIcon && !loading"
      class="mr-2"
      :class="[iconSizeMap]"
    />
    <slot />
    <component
      :is="rightIcon"
      v-if="rightIcon"
      class="ml-2"
      :class="[iconSizeMap]"
    />
  </button>
</template>

<script lang="ts" setup>
import { PropType, computed } from "vue"
import ObjectValues = API.ObjectValues;

const props = defineProps({
  variant: {
    type: String as PropType<ObjectValues<ButtonVariant>>,
  },
  outline: {
    type: Boolean,
  },
  size: {
    type: String as PropType<ObjectValues<ButtonSize>>,
    default: 'md',
  },
  square: {
    type: Boolean,
  },
  circle: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  type: {
    type: String,
    default: 'button',
  },
  leftIcon: {
    type: Object,
  },
  rightIcon: {
    type: Object,
  },
})

enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  error = 'error',
  link = 'link',
}

enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

const variantMap = computed(() => {
  return {
    'btn-primary': props.variant === ButtonVariant.primary,
    'btn-outline text-gray-600 text-sm border-gray-300': props.variant === ButtonVariant.secondary,
    'btn-success': props.variant === ButtonVariant.success,
    'btn-error': props.variant === ButtonVariant.error,
    'btn-link': props.variant === ButtonVariant.link,
  }
})

const sizeMap = computed(() => {
  return {
    'btn-xs': props.size === ButtonSize.xs,
    'btn-sm': props.size === ButtonSize.sm,
    'btn-lg': props.size === ButtonSize.lg,
  }
})

const iconSizeMap = computed(() => {
  return {
    'w-3 h-3': props.size === ButtonSize.sm || props.size === ButtonSize.xs,
    'w-4 h-4': props.size === ButtonSize.md,
    'w-6 h-6': props.size === ButtonSize.lg,
  }
})
</script>
