<template>
  <div class="flex flex-col h-full">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ $t($route.name) }}
    </PageTitle>
    <HolidayTabs />
    <BaseFormCard
      :submit-text="$t('Continue')"
      @cancel="resetInterval"
      @submit="onRangeSelected"
    >
      <FormCardSection
        :title="$t('Select Interval')"
        container-class="max-w-6xl"
      >
        <div class="col-span-6 flex space-x-2 justify-end">
          <BaseButton
            v-if="rangeStart"
            variant="secondary"
            size="sm"
            @click="resetInterval"
          >
            <XMarkIcon class="w-4 h-4 mr-1" />
            <span>{{ $t('Reset Interval') }}</span>
          </BaseButton>
          <FormKit
            v-model="monthsCount"
            :options="monthCountOptions"
            type="customSelect"
            name="Duration"
          />
        </div>
        <div
          v-loading="holidayStore.plannerHolidaysLoading"
          class="col-span-6 my-4 overflow-x-auto relative"
        >
          <div class="flex items-center">
            <div class="w-[160px] min-w-[140px]">
              <BaseButton variant="link" size="xs" class="w-full" @click="prevInterval">
                <ChevronUpIcon class="w-5 h-5" />
              </BaseButton>
            </div>
            <span v-for="day in days" :key="day">
              <span class="day-cell text-gray-600 text-sm">
                {{ day }}
              </span>
            </span>
          </div>
          <div
            v-for="month in monthsCount"
            :key="month"
            class="flex items-center relative"
          >
            <span class="bg-white sticky left-0 py-1.5 text-sm text-gray-700 w-[160px] min-w-[140px] capitalize">
              {{ getFormattedMonth(month) }}
            </span>
            <span
              v-for="(day, index) in getMonthDays(month)"
              :key="index"
              @mouseover="hoverDay = day"
              @click="onDayClick(day)"
            >
              <span
                :class="{
                  'ring-2 ring-primary': isToday(day),
                  'is-weekend !bg-primary/10': isWeekend(day) && !isNationalHolidayDate(day) && !isHolidayDate(day),
                  '!bg-green-600 !text-gray-200': isHolidayDate(day),
                  'bg-orange-400': isWithinRange(day),
                  'bg-orange-200': isRangeStart(day) || isRangeEnd(day),
                  '!bg-primary !text-gray-200': isNationalHolidayDate(day),
                }"
                class="day-cell cursor-pointer text-gray-600 text-sm"
              >
                <div
                  v-if="isRangeStart(day) || isRangeEnd(day)"
                  class="bg-orange-400 w-5 h-5 rounded-full"
                />
                <span v-else class="capitalize">
                  {{ $formatDate(day, 'EEEEEE') }}
                </span>
              </span>
            </span>
          </div>
          <div>
            <div class="w-[160px] min-w-[140px]">
              <BaseButton variant="link" size="xs" class="w-full" @click="nextInterval">
                <ChevronDownIcon class="w-5 h-5" />
              </BaseButton>
            </div>
          </div>
        </div>
        <div class="col-span-6 my-4 flex flex-col-reverse gap-y-4 sm:flex-row items-start sm:gap-x-4">
          <HolidayPlannerLegend />
          <SelectedPlannerInterval
            :start="rangeStart"
            :end="rangeEnd"
            class="flex-1 h-full"
          />
        </div>
      </FormCardSection>
    </BaseFormCard>
    <HolidayRequestForm
      v-if="showAddDialog"
      v-model="showAddDialog"
      :dialog="true"
      :title="$t('Request Holiday')"
      :employee-id="userEmployeeId"
      :start="rangeStart"
      :end="rangeEnd"
      @save="onHolidaySave"
      @close="showAddDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import addMonths from "date-fns/addMonths"
import { addDays, eachDayOfInterval, format, isAfter, isBefore, isSameDay, isWeekend } from "date-fns"
import endOfMonth from "date-fns/endOfMonth"
import startOfMonth from "date-fns/startOfMonth"
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { useAuth } from "@/modules/auth/composables/useAuth"
import HolidayTabs from "@/modules/holidays/components/HolidayTabs.vue"
import HolidayPlannerLegend from "@/modules/holidays/components/planner/HolidayPlannerLegend.vue"
import SelectedPlannerInterval from "@/modules/holidays/components/planner/SelectedPlannerInterval.vue"
import { isHoliday, isNationalHoliday, rangeIncludesHoliday } from "@/modules/holidays/utils/holidayUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import HolidayRequestForm from "@/modules/holidays/components/HolidayRequestForm.vue"
import { useHolidayStore } from "@/modules/holidays/store/holidayStore"
import { API_DATE_FORMAT, CalendarDateFormat, formatDate } from "@/modules/common/utils/dateUtils"
import { error } from "@/components/common/NotificationPlugin"
const { userEmployeeId } = useAuth()

const monthsCount = ref(4)
const today = new Date()
const yesterday = addDays(today, -1)
const showAddDialog = ref(false)

const { t } = useI18n()

const monthCountOptions = computed(() => {
  return [
    { label: t('4 months'), value: 4 },
    { label: t('6 months'), value: 6 },
    { label: t('12 months'), value: 12 },
  ]
})

const days = Array.from({ length: 31 }, (_, i) => i + 1)

const startMonth = ref(startOfMonth(today))

const endMonth = computed(() => {
  return addMonths(startMonth.value, monthsCount.value - 1)
})

function prevInterval() {
  startMonth.value = addMonths(startMonth.value, -monthsCount.value)
}

function nextInterval() {
  startMonth.value = addMonths(startMonth.value, monthsCount.value)
}

const settingsStore = useSettingsStore()
const { legalHolidays } = storeToRefs(settingsStore)

function isNationalHolidayDate(date: Date) {
  return isNationalHoliday({
    date,
    nationalHolidays: legalHolidays.value,
  })
}

const hoverDay = ref<Date | null>(null)
const rangeStart = ref<Date | null>(null)
const rangeEnd = ref<Date | null>(null)

const hasRange = computed(() => {
  return !!rangeStart.value && !!rangeEnd.value
})

function onDayClick(day: Date) {
  if (isHolidayDate(day)) {
    error(t('There already is a past holiday on this day'))
    return
  }
  if (!rangeStart.value) {
    rangeStart.value = day
    return
  }
  if (isBefore(day, rangeStart.value)) {
    return
  }
  if (rangeIncludesHoliday(rangeStart.value, day, holidayStore.plannerHolidays)) {
    error(t('There is a holiday in the selected range'))
    return
  }
  rangeEnd.value = day
}

function resetInterval() {
  rangeStart.value = null
  rangeEnd.value = null
}

function isRangeStart(day: Date) {
  if (!rangeStart.value) {
    return false
  }
  return isSameDay(day, rangeStart.value)
}

function isRangeEnd(day: Date) {
  if (!rangeEnd.value) {
    return false
  }
  return isSameDay(day, rangeEnd.value)
}

function isWithinRange(day: Date) {
  if (!rangeStart.value) {
    return false
  }
  let hoveredDay = hoverDay.value
  if (hoveredDay) {
    hoveredDay = addDays(hoveredDay, 1)
  }
  const end = rangeEnd.value || hoveredDay
  if (!end) {
    return false
  }
  return isAfter(day, rangeStart.value) && isBefore(day, end)
}

function onRangeSelected() {
  if (!hasRange.value) {
    error(t('Please select a range'))
    return
  }
  showAddDialog.value = true
}

function onHolidaySave() {
  resetInterval()
}

function getFormattedMonth(month: number) {
  return formatDate(addMonths(startMonth.value, month - 1), 'MMMM yyyy')
}

function getMonthDays(month: number) {
  const monthStart = addMonths(startMonth.value, month - 1)
  const monthEnd = endOfMonth(monthStart)
  return eachDayOfInterval({ start: monthStart, end: monthEnd })
}

function isToday(date: Date) {
  return isSameDay(date, today)
}

const holidayStore = useHolidayStore()

const plannerParams = computed(() => {
  const start = format(startMonth.value as Date, CalendarDateFormat)
  const end = format(endMonth.value as Date, CalendarDateFormat)
  return {
    start_date: `${start},${end}`,
  }
})

function isHolidayDate(date: Date) {
  return isHoliday(date, holidayStore.plannerHolidays)
}

watch(() => plannerParams.value, (params) => {
  holidayStore.getPlannerHolidays(plannerParams.value)
})

onMounted(() => {
  holidayStore.getPlannerHolidays(plannerParams.value)
})
</script>

<style>
.day-cell {
  @apply w-8 h-8 flex items-center justify-center;
}
</style>

<route lang="yaml">
name: Holiday Planner (Beta)
</route>
