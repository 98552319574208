<template>
  <div class="col-span-full flex items-center justify-center">
    <label for="photo" class="block text-sm font-medium leading-6 text-gray-900">
      <span class="mt-2 flex justify-center items-center flex-col relative group">
        <BaseAvatar
          :src="src"
          :name="name"
          class="h-[100px] w-[100px] rounded-full object-cover object-top cursor-pointer" aria-hidden="true"/>
        <button
          type="button"
          class="btn opacity-0 group-hover:opacity-100 btn-sm btn-circle btn-primary absolute top-[-5px] right-[-5px]"
          @click="removeFile"
        >
          <XMarkIcon class="w-4 h-4" />
        </button>
        <span class="mt-2">
          <span
            class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            role="button"
          >
            {{ t('Change photo') }}
          </span>
        </span>
      </span>
    </label>
    <input
      id="photo"
      name="file-upload"
      type="file"
      accept="image/*"
      class="w-0 opacity-0"
      @change="onFileChange"
    >
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useI18n } from "vue-i18n"
import BaseAvatar from "@/components/common/BaseAvatar.vue"
import { fileToDataURL } from "@/modules/documents/utils/documentUtils"
import { XMarkIcon } from "@heroicons/vue/24/outline"
import { error } from "@/components/common/NotificationPlugin";

const props = defineProps({
  modelValue: {
    type: [String],
  },
  name: {
    type: String,
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const src = ref(props.modelValue)

const maxFileSizeInBytes = 1 * 1024 * 1024
async function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (!file) {
    return
  }
  if (file.size > maxFileSizeInBytes) {
    error(t('Avatar file size should be less than 1MB'))
    return
  }
  emit('update:modelValue', file)
  src.value = await fileToDataURL(file)
}

function removeFile() {
  emit('update:modelValue', null)
  src.value = ''
}

watch(() => props.modelValue, (value: string) => {
  if (value !== src.value) {
    src.value = value
  }
})
</script>
