import { ColDef, GridApi } from "@ag-grid-community/core"
import { UnwrapRef } from "vue"
import Data = API.Data;

export enum TableActions {
  Add = 'add',
  BulkActions = 'bulk',
  Export = 'export',
  Refresh = 'refresh',
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  AfterDelete = 'afterDelete',
  Archive = 'archive',
}

export function hasAction(actions: string, action: TableActions) {
  return actions?.includes(action)
}

export function getSortProp(params: any, gridApi: UnwrapRef<GridApi | null>) {
  gridApi?.showLoadingOverlay()
  const columns = params.columnApi.getColumnState()
  const sortOption: any = {}
  columns.forEach((column: any) => {
    if (column.sort) {
      sortOption.prop = column.colId
      sortOption.order = column.sort
    }
  })

  let sortProp = null
  if (sortOption.order === 'asc') {
    sortProp = sortOption.prop
  }
  if (sortOption.order === 'desc') {
    sortProp = `-${sortOption.prop}`
  }
  sortProp = sortProp?.replace('attributes.', '')

  return sortProp
}

export function isColumnSortable(column: ColDef, gridContext: any) {
  let field = column.field || ''
  field = field.replace('attributes.', '')
  let sortables = gridContext.filterOptions.sortables || []

  if (!sortables.length) {
    return column.sortable || false
  }

  sortables = sortables.map(s => s.column)
  return sortables.includes(field)
}

export function canDeleteRow(data: Data<any>) {
  const { meta, attributes } = data || {}
  if (attributes?.is_used) {
    return false
  }
  return meta?.authorizedToDelete
}

