<template>
  <div>
    <label
      v-if="!disabled"
      ref="dropZoneRef"
      for="file-input"
      :class="{
        'border-primary': isOverDropZone,
        'border-input': !isOverDropZone,
        'py-12': size === Sizes.Large,
        'py-8': size === Sizes.Medium,
        'py-6': size === Sizes.Small,
      }"
      class="dropzone cursor-pointer py-12 flex items-center justify-center w-full rounded-sm mb-9"
    >
      <div class="flex flex-col items-center space-y-2">
        <FileIcon
          background-class="text-primary/10"
          icon-class="text-primary"
          size="lg"
        />
        <div
          :class="{
            'text-base': [Sizes.Medium, Sizes.Large].includes(size),
            'text-sm': size === Sizes.Small,
          }"
          class="text-base-300"
        >
          <div for="file-input">
            <template v-if="multiple">
              {{ $t('Drag & Drop files here') }}
            </template>
            <template v-else>
              {{ $t('Drag & Drop file here') }}
            </template>
            <div class="text-center">{{ $t('or') }} <span class="text-primary font-semibold">{{ $t('Browse') }}</span>
            </div>
          </div>
          <span>
            <input
              id="file-input"
              type="file"
              class="hidden"
              :multiple="multiple"
              :accept="accept"
              @change="onFileChange"
            >
          </span>
        </div>
      </div>
    </label>
    <div class="flex flex-col space-y-6">
      <PendingFileInfo
        v-for="(file, index) in selectedFiles"
        :key="file?.name || index"
        :file="file"
        :file-name="file?.name || fileName"
        :progress="fileProgress[file?.name || file]?.progress"
        @close="removeFile(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue"
import { useDropZone } from "@vueuse/core"
import { useI18n } from "vue-i18n"
import FileIcon from './FileIcon.vue'
import PendingFileInfo from './PendingFileInfo.vue'
import { error } from "@/components/common/NotificationPlugin"
import { FileProgress, SizeType, Sizes } from "@/modules/documents/types/documentTypes"
import { getFileExtension, isAcceptedFile } from "@/modules/documents/utils/documentUtils";

const props = defineProps({
  multiple: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: [Array, Object],
    default: () => [] as PropType<File[] | File | string>,
  },
  fileName: {
    type: String,
  },
  fileProgress: {
    type: Object as PropType<FileProgress>,
    default: () => ({}),
  },
  size: {
    type: String as PropType<SizeType>,
    default: Sizes.Large,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  accept: {
    type: String,
  },
})
const emit = defineEmits(['update:modelValue'])
const dropZoneRef = ref<HTMLDivElement>()
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)

const { t } = useI18n()
function onDrop(files: File[] | null) {
  const filesArray = Array.from(files || [])
  if (filesArray.length > 1) {
    error(t('You can only upload one file at a time'))
    return
  }
  const invalidFiles = filesArray.filter((file: File) => !isAcceptedFile(file))
  if (invalidFiles.length) {
    error(t('This file type is not accepted'))
    return
  }
  if (props.multiple) {
    selectedFiles.value.push(...filesArray)
  } else {
    selectedFiles.value = filesArray
  }
  emitEvent()
}

function emitEvent() {
  if (!props.multiple) {
    emit('update:modelValue', selectedFiles.value[0])
  } else {
    emit('update:modelValue', selectedFiles.value)
  }
}

function onFileChange(event: Event) {
  const files = (event.target as HTMLInputElement).files || []
  const filesArray: File[] = Array.from(files || [])
  onDrop(filesArray)
}

function removeFile(index: number) {
  selectedFiles.value.splice(index, 1)
  emitEvent()
}

const selectedFiles = ref<File[] | string[]>(props.modelValue as File[])
if (!props.multiple && props.modelValue) {
  selectedFiles.value = [props.modelValue as any]
}
</script>

<style scoped>
.dropzone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C4D0D8FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dropzone:hover {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23149EA3FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>

