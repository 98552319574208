<template>
  <div class="flex flex-col overflow-x-auto">
    <template v-if="hasPolicies">
      <div v-if="hasPolicyForType(HolidayTypes.TimeOff)"
           class="flex flex-col mt-6">
        <div class="flex space-x-2 items-end mb-4">
          <component
            :is="HolidayTypeIcons[HolidayTypes.TimeOff]"
            class="h-6 text-primary"
          />
          <h5 class="leading-none">
            {{ HolidayTypeLabels[HolidayTypes.TimeOff] }}
          </h5>
        </div>
        <div class="flex flex-col space-y-2">
          <PolicyItem
            v-for="(policy, index) in groupedPolicies[HolidayTypes.TimeOff]"
            :key="policy.id"
            :policy="policy"
            :read-only="true"
            :variant="variant"
            :employee-id="employeeId"
            :show-headers="index === 0"
            :show-balance="true"
            :show-initial-balance="showInitialBalance"
            :start-date="startDate"
            size="md"
          >
            <template #balance>
              <slot name="balance"></slot>
            </template>
          </PolicyItem>
        </div>
      </div>
      <div v-if="hasPolicyForType(HolidayTypes.Other)" class="flex flex-col mt-6">
        <div class="flex space-x-2 items-end mb-4">
          <component
            :is="HolidayTypeIcons[HolidayTypes.Other]"
            class="h-6 text-primary"
          />
          <h5 class="leading-none">
            {{ HolidayTypeLabels[HolidayTypes.Other] }}
          </h5>
        </div>
        <div class="flex flex-col space-y-2">
          <PolicyItem
            v-for="policy in groupedPolicies[HolidayTypes.Other]"
            :key="policy.id"
            :policy="policy"
            :read-only="true"
            :variant="variant"
            :employee-id="employeeId"
            :show-balance="true"
            :start-date="startDate"
            size="md"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <h5 class="leading-none mt-4">
        {{ $t('Choose a contract type for the employee to view holiday information') }}
      </h5>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { HolidayTypeIcons, HolidayTypeLabels, HolidayTypes } from "@/modules/settings/types/settingTypes";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { computed, PropType } from "vue";
import PolicyItem from "@/modules/settings/components/holidays/PolicyItem.vue";

const props = defineProps({
  employeeId: {
    type: String,
  },
  contractType: {
    type: String,
  },
  variant: {
    type: String as PropType<"table" | "card">,
    default: 'card',
  },
  showInitialBalance: {
    type: Boolean,
    default: false,
  },
  startDate: {
    type: Date,
  },
})

const settingsStore = useSettingsStore()

const groupedPolicies = computed(() => {
  return settingsStore.groupedPoliciesForContractType(props.contractType as string)
})

const hasPolicies = computed(() => {
  return Object.keys(groupedPolicies.value).length > 0
})

function hasPolicyForType(type: string) {
  return groupedPolicies.value[type]?.length > 0
}
</script>
