<template>
  <div>
    <PageTitle>
      {{ t('New invoice for', { clientName: client?.attributes?.company_name }) }}
    </PageTitle>
    <BaseFormCard
      :submit-text="$t('Review Invoice')"
      class="mt-4"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <FormCardSection :title="t('Invoice Details')" container-class="max-w-6xl">
        <FormKit
          v-model="model.client_id"
          :label="t('Client')"
          validation="required"
          type="clientSelect"
          outer-class="min-w-[240px]"
          @update:modelValue="getTimesheetEntries"
        />
        <div class="col-span-6" />
        <FormKit
          v-model="reportsStore.currentReport.intervalType"
          :options="intervalTypeOptions"
          :label="$t('Billable Interval')"
          type="customSelect"
          outer-class="min-w-[240px]"
          @update:modelValue="onIntervalChange"
        />
        <div
          v-if="reportsStore.currentReport.intervalType === IntervalTypes.Custom"
          class="col-span-6 mt-2"
        >
          <div
            class="flex items-center space-x-2 py-4 print:py-2 mt-2 px-3 bg-gray-50 border border-gray-300 rounded-sm"
          >
            <div class="font-medium text-sm">
              {{ t('View report from') }}
            </div>
            <FormKit
              v-model="reportsStore.customRange.from"
              :placeholder="t('From')"
              type="customDatePicker"
              outer-class="min-w-[240px]"
            />
            <span>{{ t('to') }}</span>
            <FormKit
              v-model="reportsStore.customRange.to"
              :placeholder="t('To')"
              type="customDatePicker"
              outer-class="min-w-[240px]"
            />
            <div class="ml-4">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="onIntervalChange"
              >
                {{ t('Update interval') }}
              </button>
            </div>
          </div>
        </div>
        <FormKit
          v-model="model.group"
          :label="t('Group by')"
          :options="groupOptions"
          validation="required"
          type="radio"
          outer-class="col-span-6 mt-4"
          @update:modelValue="getTimesheetEntries"
        />
      </FormCardSection>
      <FormCardSection :title="t('Review amounts')" container-class="max-w-6xl">
        <div class="flex col-span-6 w-full justify-end">
          <div class="flex flex-col max-w-[250px] w-full items-end h-full justify-center pt-2 pr-4">
            <span class="text-sm text-gray-600">{{ t('Total hours') }}</span>
            <span v-if="loading" class="h-7 bg-gray-100 animate-pulse w-16 rounded" />
            <span v-else class="text-xl font-semibold">{{ totalHours }}</span>
          </div>
          <div
            class="flex flex-col max-w-[250px] w-full items-end h-full justify-center pt-2 pr-4"
          >
            <span class="text-sm text-gray-600">{{ t('Billable amount') }}</span>
            <span v-if="loading" class="h-7 bg-gray-100 animate-pulse w-24 rounded" />
            <span v-else class="text-xl font-semibold">{{ formatPrice(totalBillableAmount) }}</span>
          </div>
        </div>
        <BaseDataTable
          :data="timesheetEntries"
          :columns="columns"
          :pagination="false"
          :row-height="55"
          :hide-search="true"
          :data-loading="loading"
          dom-layout="autoHeight"
          class="col-span-6"
        >
          <template #name="{ row }">
            <EmployeeLink
              v-if="model.group === groupMap[ReportTypes.Team].value"
              :params="{ value: row?.attributes?.entity_id }"
            />
            <div v-else>
              {{ row?.attributes?.entity_name }}
            </div>
          </template>
        </BaseDataTable>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core"
import axios from "axios"
import { useClientStore } from "@/modules/clients/store/clientStore"
import FormCardSection from "@/components/form/FormCardSection.vue"
import { IntervalTypes, ReportTypes, useReportsStore } from "@/modules/reports/store/reportsStore"
import Data = API.Data
import Client = App.Domains.Clients.Models.Client
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import { invoiceIntervalTypes, reportIntervalTypes } from "@/modules/reports/enum/reportEnums"
import { API_REPORT_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { isMobile } from "@/util/responsiveUtils"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import { formatHours } from "@/modules/reports/utils/reportUtils"
import { can } from "@/plugins/permissionPlugin"
import { formatPrice } from "@/plugins/formatPrice"
import { useInvoiceStore } from "@/modules/invoices/store/invoiceStore"

const clientStore = useClientStore()
const { t } = useI18n()
const route = useRoute()

const reportsStore = useReportsStore()

const model = ref({
  client_id: route.query.client_id,
  group: 'employee_id',
})

const intervalTypeOptions = invoiceIntervalTypes()
reportsStore.currentReport.intervalType = IntervalTypes.LastMonth


const client = computed<Data<Client>>(() => {
  return clientStore.getClientById(model.value.client_id as string)
})

const columns = computed(() => {
  return [
    {
      headerName: t('Name'),
      field: 'name',
      minWidth: isMobile() ? 180 : 200,
    },
    {
      headerName: t('Hours'),
      field: 'total_minutes',
      type: 'rightAligned',
      cellClass: 'font-medium text-right',
      headerClass: 'ag-right-aligned-header',
      minWidth: isMobile() ? 140 : 200,
      sortable: true,
      sortingOrder: ['desc', 'asc', null],
      valueGetter: (params: ValueGetterParams) => {
        return +params.data?.attributes?.total_worked_minutes
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return formatHours(params.data)
      },
    },
    {
      headerName: t('Billable amount'),
      field: 'attributes.total_billed_rate',
      type: 'rightAligned',
      sortable: true,
      sortingOrder: ['desc', 'asc', null],
      cellClass: (params: CellClassParams) => {
        const { no_logs } = params.data?.attributes || {}
        if (no_logs) {
          return 'text-gray-500 text-right'
        }
        return 'font-bold text-right'
      },
      headerClass: 'ag-right-aligned-header',
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) => {
        let value = params.value
        if (params.value === null || params.value === undefined) {
          value = 0
        }
        return formatPrice(value, {
          maximumFractionDigits: 2,
        })
      },
      valueGetter: (params: ValueGetterParams) => {
        return +params.data?.attributes?.total_billed_rate
      },
    },
  ]
})

const groupMap = computed(() => {
  return {
    [ReportTypes.Team]: {
      label: t('By Person: Combine all hours for one person into one entry'),
      value: 'employee_id',
    },
    [ReportTypes.Tasks]: {
      label: t('By Task: Combine all hours for one task into one entry'),
      value: 'task_id',
    },
    [ReportTypes.Projects]: {
      label: t('By Project: Combine all hours for one project into one entry'),
      value: 'project_id',
    },
  }
})

const groupOptions = computed(() => {
  return Object.values(groupMap.value).map((option) => {
    return {
      label: option.label,
      value: option.value,
    }
  })
})

const timesheetEntries = ref<Data<Timesheet>[]>([])
const timesheetEntriesMeta = ref<any>({})
const loading = ref(false)

const totalBillableAmount = computed(() => {
  return timesheetEntriesMeta.value?.total_billed_amount || 0
})

const totalHours = computed(() => {
  return timesheetEntriesMeta.value?.total_worked_hours || 0
})

function onIntervalChange() {
  reportsStore.changeReportRange()
  getTimesheetEntries()
}

async function getTimesheetEntries() {
  try {
    loading.value = true
    const from = formatDate(reportsStore.currentReport.from, API_REPORT_DATE_FORMAT)
    const to = formatDate(reportsStore.currentReport.to, API_REPORT_DATE_FORMAT)
    const { data, meta }: any = await axios.get('/restify/timesheets', {
      params: {
        client_id: model.value.client_id,
        group: model.value.group,
        date: `${from},${to}`,
        perPage: 500,
      },
    })
    timesheetEntries.value = data.map((row: any) => {
      return {
        id: crypto.randomUUID(),
        ...row,
      }
    })
    timesheetEntriesMeta.value = meta
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  getTimesheetEntries()
})

const router = useRouter()
const invoiceStore = useInvoiceStore()

function onSubmit() {
  invoiceStore.timesheetEntriesForInvoice = timesheetEntries.value
  const from = formatDate(reportsStore.currentReport.from, API_REPORT_DATE_FORMAT)
  const to = formatDate(reportsStore.currentReport.to, API_REPORT_DATE_FORMAT)
  router.push({
    path: '/invoices/create',
    query: {
      ...route.query,
      from,
      to,
      tracked_time: true,
      group: model.value.group,
    },
  })
}

function onCancel() {
  router.push('/invoices')
}
</script>

<route lang="yaml">
name: New Invoice based on Tracked Time
</route>
