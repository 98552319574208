<template>
  <div class="flex flex-col">
    <div class="flex items-center space-x-2 mt-2 md:mt-5 print:mt-2 print:space-x-0">
      <ArrowLongLeftIcon
        v-if="showBack"
        class="min-w-[20px] w-5 h-5 text-primary cursor-pointer print:hidden"
        @click="goBack"
      />
      <h2 v-if="loading" class="bg-gray-100 animate-pulse rounded h-9 w-[200px]"/>
      <h2 v-else>
        <slot/>
      </h2>
      <slot name="content"/>
    </div>
    <PageBreadcrumbs v-if="showBreadCrumbs" class="mb-2 md:mb-4 mt-2"/>
  </div>
</template>
<script setup lang="ts">
import { ArrowLongLeftIcon } from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";
import PageBreadcrumbs from "@/components/common/PageBreadcrumbs.vue";

const props = defineProps({
  showBack: {
    type: Boolean,
    default: true,
  },
  showBreadCrumbs: {
    type: Boolean,
    default: true,
  },
  loading: Boolean,
})

const router = useRouter()

async function goBack() {
  await router.go(-1)
}
</script>
