<template>
  <div class="flex flex-col sm:flex-row space-x-2">
    <div class="space-y-2 mb-4 flex flex-col min-w-[260px] sm:max-w-[260px] lg:min-w-[290px] sm:max-w-[290px]">
      <EmployeePreviewCard :employee="currentEmployee">
        <template
          v-if="$can('manageEmployees')"
          #actions
        >
          <CardActionButton
            v-if="currentEmployee.status === EmployeeStatus.Draft"
            class="mt-8"
            :icon="PaperAirplaneIcon"
            :title="$t('Send invite')"
            :loading="invitationSending"
            @click="onSendInvite"
          />

          <CardActionButton
            v-if="[EmployeeStatus.Invited].includes(currentEmployee.status)"
            class="mt-8 -mb-6"
            :icon="XCircleIcon"
            :title="$t('Cancel Invite')"
            :loading="invitationCancelling"
            @click="onCancelInvite"
          />

          <CardActionButton
            v-if="[EmployeeStatus.Invited, EmployeeStatus.Cancelled].includes(currentEmployee.status)"
            class="mt-8"
            :icon="PaperAirplaneIcon"
            :title="$t('Resend invite')"
            :loading="invitationResending"
            @click="onResendInvite"
          />
        </template>
      </EmployeePreviewCard>
      <BaseContentCard>
        <div class="text-sm flex flex-col space-y-4">
          <div class="flex items-center space-x-3">
            <EnvelopeIcon class="detail-icon" />
            <a
              :href="`mailto:${currentEmployee.email}`"
              :title="currentEmployee.email"
              target="_blank"
              rel="noopener"
              class="text-base-content no-underline hover:underline truncate"
            >
              {{ currentEmployee.email }}
            </a>
          </div>
          <div class="flex items-center space-x-3">
            <PhoneIcon class="detail-icon" />
            <a
              :href="`tel:${currentEmployee.phone}`"
              target="_blank"
              rel="noopener"
              class="text-base-content no-underline hover:underline"
            >
              {{ currentEmployee.phone }}
            </a>
          </div>
          <div class="flex items-center space-x-3">
            <ClockIcon class="detail-icon" />
            <p>{{ getTimezoneLabel(currentEmployee.timezone) }}</p>
          </div>
          <div class="flex items-center space-x-3">
            <MapPinIcon class="detail-icon" />
            <AddressLink :address="currentEmployee.address" />
          </div>
        </div>
        <CardActionButton
          v-if="!isOwnEmployee"
          class="mt-8"
          :icon="EnvelopeIcon"
          :title="$t('Contact')"
          @click="onContact"
        />
      </BaseContentCard>
      <EmployeeAboutCard
        v-if="$can('manageEmployees') || isOwnEmployee"
        :employee="currentEmployee"
      />
    </div>
    <div class="space-y-2 mb-4 flex flex-1 flex-col">
      <BaseContentCard :title="$t('Employment details')">
        <ContentCardRow :title="$t('Start Date')">
          {{ $formatDate(currentEmployee.start_date, FriendlyDateFormat) || '- -' }}
        </ContentCardRow>
        <ContentCardRow :title="$t('End Date')">
          {{ $formatDate(currentEmployee.termination_date, FriendlyDateFormat) || '- -' }}
        </ContentCardRow>
        <template v-if="$can('manageEmployees') || isOwnEmployee">
          <ContentCardRow :title="$t('Salary type')">
            <HiddenDetails>
              {{ t(getSalaryTypeLabel(currentEmployee.salary_type)) || '- -' }}
            </HiddenDetails>
          </ContentCardRow>
          <ContentCardRow :title="$t('Amount')">
            <HiddenDetails>
              <span class="lowercase">
                {{ $formatPrice(currentEmployee.salary, { currency: currentEmployee.salary_currency }) || '- -' }}
                {{ t(getSalaryTypeLabel(currentEmployee.salary_type)) }}
              </span>
            </HiddenDetails>
          </ContentCardRow>

          <ContentCardRow :title="$t('Min Capacity')">
            {{ currentEmployee.weekly_hours_capacity || '- -' }} {{ $t('h / week') }}
          </ContentCardRow>
        </template>

        <ContentCardRow :title="$t('Position')">
          <span v-if="currentEmployee.relationships?.level" class="capitalize">
            {{ currentEmployee.relationships?.level?.attributes.name }}
          </span>
          {{ currentEmployee.relationships?.position?.attributes?.name || '- -' }}
        </ContentCardRow>
      </BaseContentCard>

      <BaseContentCard
        v-if="$can('manageEmployees') || isOwnEmployee"
        :title="$t('Contract information')"
      >
        <ContentCardRow :title="$t('Contract Type')">
          {{ getContractTypeLabel(currentEmployee.contract_type) || '- -' }}
        </ContentCardRow>
        <template v-if="isB2BContract(currentEmployee.contract_type)">
          <ContentCardRow :title="$t('Company name')">
            {{ currentEmployee.company_name || '- -' }}
          </ContentCardRow>
          <ContentCardRow :title="$t('Company position')">
            {{ currentEmployee.company_position || '- -' }}
          </ContentCardRow>
          <ContentCardRow :title="$t('VAT Number')">
            <HiddenDetails>
              {{ currentEmployee.company_vat || '- -' }}
            </HiddenDetails>
          </ContentCardRow>
          <ContentCardRow :title="$t('Company Register No.')">
            <HiddenDetails>
              {{ currentEmployee.company_identifier || '- -' }}
            </HiddenDetails>
          </ContentCardRow>
          <ContentCardRow :title="$t('Office Address')">
            <HiddenDetails>
              <AddressLink :address="currentEmployee.company_address" />
            </HiddenDetails>
          </ContentCardRow>
        </template>
        <ContentCardRow :title="$t('Bank')">
          <HiddenDetails>
            {{ currentEmployee.contract_bank_name || '- -' }}
          </HiddenDetails>
        </ContentCardRow>
        <ContentCardRow :title="$t('Swift')">
          <HiddenDetails>
            {{ currentEmployee.contract_bank_swift || '- -' }}
          </HiddenDetails>
        </ContentCardRow>
        <ContentCardRow :title="$t('Account')">
          <HiddenDetails>
            {{ currentEmployee.contract_bank_account || '- -' }}
          </HiddenDetails>
        </ContentCardRow>
      </BaseContentCard>

      <BaseContentCard
        v-if="$can('manageEvaluations') || isOwnEmployee"
        :title="$t('Evaluations')"
      >
        <ContentCardRow :title="$t('Technical')">
          {{ getEmployeeNamesFromIds(currentEmployee.technical_evaluation_assignees) || $t('No assignees') }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Salary')">
          {{ getEmployeeNamesFromIds(currentEmployee.salary_evaluation_assignees) || $t('No assignees') }}
        </ContentCardRow>
      </BaseContentCard>
      <EmployeeAboutCard
        v-if="!$can('manageEmployees') && !isOwnEmployee"
        :employee="currentEmployee"
      />
      <BaseContentCard
        v-if="$can('manageHolidays') || isOwnEmployee"
        :title="$t('Holidays')"
      >
        <EmployeeHolidayPolicies
          :employee-id="currentEmployee.id"
          :contract-type="currentEmployee.contract_type"
          variant="table"
          class="col-span-6"
        />
      </BaseContentCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import {
  CakeIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  MapPinIcon,
  PaperAirplaneIcon,
  PhoneIcon, XCircleIcon,
} from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import BaseContentCard from "@/components/common/BaseContentCard.vue"
import ContentCardRow from "@/components/common/ContentCardRow.vue"
import {
  EmployeeStatus,
  getContractTypeLabel,
  getSalaryTypeLabel,
  getTimezoneLabel,
  isB2BContract,
} from "@/modules/employees/utils/employeeUtils"
import { FriendlyDateFormat } from "@/modules/common/utils/dateUtils"
import { useAuthStore } from "@/modules/auth/store/authStore"
import EmployeeAboutCard from "@/modules/employees/components/EmployeeAboutCard.vue"
import EmployeeHolidayPolicies from "@/modules/employees/components/EmployeeHolidayPolicies.vue"
import EmployeePreviewCard from "@/modules/employees/components/EmployeePreviewCard.vue"
import HiddenDetails from "@/components/common/HiddenDetails.vue"

const { t } = useI18n()

const employeeStore = useEmployeeStore()
const { currentEmployee, invitationSending, invitationResending, getEmployeeNamesFromIds, invitationCancelling } = storeToRefs(employeeStore)

const authStore = useAuthStore()
const { profile, isOwnEmployee } = storeToRefs(authStore)

function onContact() {
  const email = currentEmployee.value.email
  const ccEmail = profile.value.email
  const body = t(`Hi employee`, { employee: currentEmployee.value.first_name })
  const subject = ''
  window.open(`mailto:${email}?cc=${ccEmail}&subject=${subject}&body=${body}`)
}

async function onSendInvite() {
  await employeeStore.sendInvitation({
    employee_id: currentEmployee.value.id,
    email: currentEmployee.value.email,
    user_id: currentEmployee.value?.relationships?.user?.id,
  })
  currentEmployee.value.status = EmployeeStatus.Invited
}

async function onResendInvite() {
  await employeeStore.resendInvitation({
    id: currentEmployee.value.id,
    attributes: currentEmployee.value,
    relationships: currentEmployee.value.relationships,
  })
  currentEmployee.value.status = EmployeeStatus.Invited
}

async function onCancelInvite() {
  await employeeStore.cancelInvitation({
    id: currentEmployee.value.id,
    attributes: currentEmployee.value,
    relationships: currentEmployee.value.relationships,
  })
  currentEmployee.value.status = EmployeeStatus.Cancelled
}
</script>

<style scoped>
.detail-icon {
  @apply w-4 h-4 min-w-[16px] text-primary;
}
</style>
