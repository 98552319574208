<template>
  <div>
    <PageBreadcrumbs class="mb-2" />
    <div class="flex flex-wrap gap-4 justify-between report-header min-h-[38px]">
      <div class="flex flex-wrap gap-4 items-center">
        <ArrowButtons
          v-if="showArrows"
          @left-click="reportsStore.previousInterval"
          @right-click="reportsStore.nextInterval"
        />
        <ReportTitle
          :title="currentReport.title"
          :from="currentReport.from"
          :to="currentReport.to"
          :interval-type="currentReport.intervalType"
        />
        <div>
          <button
            v-if="currentReport.intervalType === IntervalTypes.Custom"
            class="btn btn-link"
            @click="reportsStore.customRange.saved = false"
          >
            {{ t('Edit interval') }}
          </button>
        </div>
      </div>
      <div>
        <FormKit
          v-if="isCommonReportType"
          v-model="currentReport.intervalType"
          :options="reportIntervalTypes"
          type="customSelect"
          outer-class="interval-select"
        />
      </div>
    </div>
    <div
      v-if="currentReport.intervalType === IntervalTypes.Custom && !customRange.saved"
      class="flex items-center space-x-2 py-4 print:py-2 mt-2 px-3 bg-gray-50 border border-gray-300 rounded-sm"
    >
      <div class="font-medium text-sm">
        {{ t('View report from') }}
      </div>
      <FormKit
        v-model="customRange.from"
        :placeholder="t('From')"
        type="customDatePicker"
        outer-class="min-w-[240px]"
      />
      <span>{{ t('to') }}</span>
      <FormKit
        v-model="customRange.to"
        :placeholder="t('To')"
        type="customDatePicker"
        outer-class="min-w-[240px]"
      />
      <div class="ml-4">
        <button class="btn btn-primary btn-sm" @click="onCustomRangeSave">
          {{ t('Update report') }}
        </button>
      </div>
    </div>
    <div class="flex justify-end pt-4 mt-4 border-t border-gray-200">
      <div class="flex flex-1 h-full pt-2 pr-4">
        <EmployeeAvatar
          v-if="isEmployeeReport"
          :data="reportEmployee"
          size="lg"
        />
        <h3 v-else class="text-xl font-semibold flex space-x-2 capitalize">
          {{ reportTitle }}
        </h3>
      </div>
      <div class="flex flex-col max-w-[250px] w-full items-end h-full justify-center pt-2 pr-4">
        <span class="text-sm text-gray-600">{{ t('Total hours') }}</span>
        <span v-if="currentReportLoading" class="h-7 bg-gray-100 animate-pulse w-16 rounded" />
        <span v-else class="text-xl font-semibold">{{ currentReportTotal }}</span>
      </div>
      <div
        v-if="$can('manageInvoices')"
        class="flex flex-col max-w-[250px] w-full items-end h-full justify-center pt-2 pr-4"
      >
        <span class="text-sm text-gray-600">{{ t('Billable amount') }}</span>
        <span v-if="currentReportLoading" class="h-7 bg-gray-100 animate-pulse w-24 rounded" />
        <span v-else class="text-xl font-semibold">{{ formatPrice(currentReportTotalCost) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import ReportTitle from '@/modules/reports/components/ReportTitle.vue'
import ArrowButtons from '@/components/common/ArrowButtons.vue'
import { IntervalTypes, ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import { reportIntervalTypes } from '@/modules/reports/enum/reportEnums'
import { formatPrice } from "@/plugins/formatPrice"
import PageBreadcrumbs from "@/components/common/PageBreadcrumbs.vue"
import { useProjectStore } from "@/modules/projects/store/projectStore"
import { getEmployeeFullName } from "@/modules/clients/utils/employeeUtils"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"

const reportsStore = useReportsStore()
const { currentReport, currentReportLoading, customRange, currentReportTotal, currentReportTotalCost } = storeToRefs(reportsStore)

const showArrows = computed(() => {
  return ![IntervalTypes.Custom, IntervalTypes.AllTime].includes(currentReport.value.intervalType)
})

const isCommonReportType = computed(() => {
  return currentReport.value.reportType !== ReportTypes.PresenceSheet
})
const { t } = useI18n()
const projectStore = useProjectStore()
const project = computed(() => {
  return projectStore.getProjectById(currentReport.value.project_id)
})

const employeeStore = useEmployeeStore()

const isEmployeeReport = computed(() => {
  return !!currentReport.value.employee_id
})

const reportEmployee = computed(() => {
  return employeeStore.getEmployeeById(currentReport.value.employee_id)
})

const reportTitle = computed(() => {
  if (currentReport.value.employee_id) {
    return getEmployeeFullName(reportEmployee.value)
  }
  return project.value?.attributes?.name || ''
})

async function onCustomRangeSave() {
  reportsStore.customRange.saved = true
  reportsStore.changeReportRange()
  await reportsStore.getCurrentReport()
}

watch(() => currentReport.value.intervalType, async (value) => {
  reportsStore.changeReportRange()
  if (value !== IntervalTypes.Custom) {
    await reportsStore.getCurrentReport()
  } else {
    reportsStore.customRange.saved = false
  }
})
</script>

<style lang="scss">
.report-header {
  .interval-select .el-input {
    @apply bg-transparent;
  }
}
</style>
