<template>
  <div ref="el">
    <h1 class="text-center">
      {{ $t('Almost there') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ $t(`Already have an account?`) }}</p>
      <RouterLink to="/login">{{ $t('Sign In') }}</RouterLink>
    </div>

    <div class="mt-10">
      <FormKit
        #default="{ state: { loading } }"
        :actions="false"
        type="form"
        @submit="onSubmit"
      >

        <FormKit
          v-model="model.username"
          :name="$t('User Name')"
          :label="$t('User Name')"
          :placeholder="$t('Enter your user name')"
          :prefix-icon="UserIcon"
          validation="required"
          validation-visibility="dirty"
        />

        <FormKit
          v-model="model.country"
          :name="$t('Country')"
          :label="$t('Country')"
          :placeholder="$t('Choose your country')"
          :prefix-icon="BriefCaseIcon"
          :select-by-code="true"
          type="countrySelect"
          validation="required"
          validation-visibility="dirty"
        />
        
        <FormKit
          v-model="model.company_name"
          :name="$t('Company Name')"
          :label="$t('Company Name')"
          :placeholder="$t('Enter your company name')"
          :prefix-icon="BriefCaseIcon"
          validation="required"
          validation-visibility="dirty"
          @update:modelValue="onNameChange"
        />

        <FormKit
          v-model="model.subdomain"
          :name="$t('Account URL')"
          :label="$t('Account URL')"
          :help="$t('Account url help text')"
          :placeholder="$t('mycompany')"
          :prefix-icon="LinkIcon"
          validation="required|domain"
          validation-visibility="dirty"
        >
          <template #suffix>
            <span class="input-suffix text-sm">.growee.net</span>
          </template>
        </FormKit>

        <div class="w-full mt-10">
          <div class="mb-4">
            <FormKit
              v-model="agreeWithTerms"
              type="checkbox"
              :name="$t('Agree Terms')"
              validation="accepted"
              validation-visibility="dirty"
            >
              <template #label>
                <div class="flex items-center space-x-1">
                  <span class="pl-2 label">{{ $t('I agree with the') }}</span>
                  <ChevronRightIcon class="w-4 text-primary"/>
                  <a target="_blank" href="https://www.growee.net/terms" class="text-sm">
                    {{ $t('Terms & Conditions') }}
                  </a>
                </div>
              </template>
            </FormKit>
          </div>
          <FormKit
            type="submit"
            :disabled="loading"
            :classes="{ input: 'w-full' }"
          >
            <span>{{ $t('Sign Up') }}</span>
            <ArrowLongRightIcon class="w-4 h-4 ml-2"/>
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLongRightIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { onMounted, reactive, ref } from "vue";
import { error } from "@/components/common/NotificationPlugin";
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { RegisterSteps } from "@/modules/auth/types/enums";
import { isRootDomain } from "@/modules/common/config";
import { useStorage } from "@vueuse/core";
import RegisterStep3 = Auth.RegisterStep3;

const UserIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
</svg>
`
const LinkIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
</svg>
`
const BriefCaseIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
</svg>
`
const props = defineProps({
  token: {
    type: String,
    default: '',
  },
})
const onBoardingStep = useStorage<RegisterSteps>('onBoardingStep', RegisterSteps.EmailSetup)
const onBoardingToken = useStorage<string>('onBoardingToken', '')
const agreeWithTerms = ref(false)

const route = useRoute()
const model: RegisterStep3 = reactive({
  username: '',
  company_name: '',
  subdomain: '',
  country: '',
  onboarding_step: RegisterSteps.CompanySetup,
  onboarding_token: props.token || route.query.onboarding_token as string,
})

const authStore = useAuthStore()
const router = useRouter()
const emit = defineEmits(['success'])
const { t, locale } = useI18n()

if (locale.value === 'ro') {
  model.country = 'RO'
}

function onNameChange(value: string) {
  model.subdomain = value.replace(' ', '').toLowerCase()
}

async function onSubmit() {
  try {
    if (!model.onboarding_token && route.query.onboarding_token) {
      model.onboarding_token = route.query.onboarding_token as string
    }
    model.locale = locale.value as string
    const data: any = await authStore.registerStep3(model)
    data.onboarding_step = RegisterSteps.TeamSetup
    onBoardingStep.value = RegisterSteps.EmailSetup
    onBoardingToken.value = ''
    if (isRootDomain()) {
      let url = data?.attributes?.frontend
      if (url) {
        url = url.replace('.app', '')
        window.location.href = `${url}?token=${data.meta.token}&locale=${locale.value}`
      }
      return
    }
    await router.push('/welcome-setup')
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }
}

const el = ref()
onMounted(() => {
  const firstInput = el.value?.querySelector('input')
  firstInput?.focus()
})
</script>
