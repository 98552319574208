


export const layouts = {
'authLayout': () => import('/home/runner/work/growee/growee/frontend/src/layouts/authLayout.vue'),
'defaultLayout': () => import('/home/runner/work/growee/growee/frontend/src/layouts/defaultLayout.vue'),
'emptyLayout': () => import('/home/runner/work/growee/growee/frontend/src/layouts/emptyLayout.vue'),
'components/Sidebar': () => import('/home/runner/work/growee/growee/frontend/src/layouts/components/Sidebar.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'defaultLayout'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
