import { Component } from "vue";
import Rest from "@/assets/icons/rest.svg";
import Other from "@/assets/icons/other.svg";
import National from "@/assets/icons/national.svg";
import i18n from "@/i18n";
import ObjectValues = API.ObjectValues;

export type SettingCard = {
  title: string
  description: string
  path: string
  icon: string | Component
  iconSize: string
  show?: () => boolean
}

export type Milestone = {
  days_per_year: number,
  years_in_company: number,
}

export const HolidayTypes = {
  Other: 'other',
  TimeOff: 'timeoff',
  National: 'national',
} as const

export const HolidayTypeLabels = {
  [HolidayTypes.TimeOff]: i18n.t('Vacation'),
  [HolidayTypes.Other]: i18n.t('Sick / Required'),
  [HolidayTypes.National]: i18n.t('National'),
} as const

export const HolidayTypeDescriptions = {
  [HolidayTypes.TimeOff]: i18n.t('General holiday for vacations, rest or any other preferences.'),
  [HolidayTypes.Other]: i18n.t('General holiday for vacations, rest or any other preferences.'),
  [HolidayTypes.National]: i18n.t('Stay informed about your National days off'),
} as const

export const HolidayTypeIcons = {
  [HolidayTypes.TimeOff]: Rest,
  [HolidayTypes.Other]: Other,
  [HolidayTypes.National]: National,
}

export const TagColors = {
  Primary: '#149ea3',
  Blue: 'rgb(9,175,255)',
  Yellow: 'rgb(252,177,0)',
  Orange: 'rgb(255,128,3)',
  Red: 'rgb(254,4,5)',
  Green: 'rgb(38,180,80)',
  Violet: 'rgb(255,0,254)',
  Magenta: 'rgb(140,5,255)',
  Gray: '#374151',
}
