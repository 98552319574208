<template>
  <div>
    <PageTitle>
      <template v-if="isEdit">
        {{ props.data?.name }}
      </template>
      <template v-else>
        {{ t('Add Project') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :loading="loading"
      class="project-form"
      :submit-text="isEdit ? $t('Update') : $t('Create')"
      v-bind="$attrs"
      @cancel="onCancel"
      @submit="onSubmit"
    >
      <FormCardSection :title="t('Project details')">
        <FormKit
          v-model="model.client_id"
          :show-add="!model.id"
          :disabled="model.id"
          :label="t('Client')"
          :placeholder="t('Select a client...')"
          type="clientSelect"
          validation="required"
          outer-class="col-span-6"
        />

        <FormKit
          v-model="model.name"
          :label="$t('Project name')"
          :placeholder="$t('Project name')"
          validation="required"
          type="text"
          outer-class="col-span-6"
        />

        <FormKit
          v-model="model.description"
          type="html"
          :label="$t('Description')"
          :placeholder="$t('Description')"
          outer-class="col-span-6"
        />

        <WarningAlert v-if="!isEdit" class="col-span-6 my-4">
          {{ t('After you save the project, you can add tasks and team members') }}
        </WarningAlert>
      </FormCardSection>

      <FormCardSection
        v-if="isEdit"
        :title="$t('Tasks')"
        title-class="text-lg"
        class="col-span-6"
      >
        <TasksList
          ref="tasksList"
          :tasks="model.tasks"
          :project-id="model.id"
        />
      </FormCardSection>

      <FormCardSection
        v-if="isEdit"
        :title="$t('Team')"
        title-class="text-lg"
        class="col-span-6"
      >
        <ProjectMembersList
          ref="employeeList"
          :employees="model.employees"
          :project-id="model.id"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { error, success } from '@/components/common/NotificationPlugin'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { useFormInit } from '@/modules/common/composables/useFormInit'
import TasksList from '@/modules/projects/components/TasksList.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import ProjectMembersList from '@/modules/projects/components/ProjectMembersList.vue'
import WarningAlert from "@/components/common/WarningAlert.vue";
import FormCardSection from "@/components/form/FormCardSection.vue";

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDialog: {
    type: Boolean,
    default: true,
  },
  data: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['save', 'cancel'])

const { t } = useI18n()

const projectStore = useProjectStore()

const model = ref({
  id: null,
  client_id: '',
  name: '',
  description: '',
  tasks: [],
  employees: [],
})

useFormInit(props, model)

const loading = ref(props.loading || false)
const router = useRouter()

function onCancel() {
  router.push('/projects')
}
async function onSubmit() {
  const hasUnsavedTask = checkForUnsavedTasks()
  const hasUnsavedMember = checkForUnsavedMembers()
  if (hasUnsavedTask || hasUnsavedMember) {
    return
  }
  try {
    let data
    if (props.isEdit) {
      data = await projectStore.updateProject(model.value)
    } else {
      data = await projectStore.createProject(model.value)
    }
    if (data) {
      await router.push(`/projects/${data.id}/details`)
    }
    if (props.isEdit) {
      success(t('Project updated successfully'))
    } else {
      success(t('Project created successfully'))
    }
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

const tasksList = ref()
const employeeList = ref()

function checkForUnsavedTasks() {
  const hasUnsavedTask = tasksList.value?.hasUnsavedTask
  if (hasUnsavedTask) {
    error(t('You have an unsaved task'))
    return true
  }
  return false
}

function checkForUnsavedMembers() {
  const hasUnsavedMember = employeeList.value?.hasUnsavedMember
  if (hasUnsavedMember) {
    error(t('You have an unsaved member'))
    return true
  }
  return false
}
</script>

<style>
.project-form #text-editor .ProseMirror {
  height: 100px;
}
</style>
