<template>
  <BaseSelect
    v-bind="allAttrs"
    filterable
    :options="currencyOptions"
    :model-value="context._value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useFormKitInput } from "@/components/formkit/useFormkitInput";
import { useI18n } from "vue-i18n";

const props = defineProps({
  context: Object,
})

const { t } = useI18n()

const currencyOptions = computed(() => {
  return [
    {
      label: t('EUR'),
      value: 'EUR'
    },
    {
      label: t('USD'),
      value: 'USD'
    },
    {
      label: t('CHF'),
      value: 'CHF'
    },
    {
      label: t('RON'),
      value: 'RON'
    },
    {
      label: t('MDL'),
      value: 'MDL'
    },
  ]
})

const { allAttrs, handleChange } = useFormKitInput(props)
</script>
