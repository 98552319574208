<template>
  <ElTooltip 
    :show-after="400"
    placement="top"
    v-bind="$attrs"
  >
    <template #content v-if="$slots.content">
      <slot name="content"></slot>
    </template>
    <slot></slot>
  </ElTooltip>
</template>
<script setup lang="ts">
</script>
