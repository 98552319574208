<template>
  <ReportData
    :report-type="ReportTypes.Team"
    :show-employees-without-logs="true"
    :employees="projectEmployees"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import ReportData from '@/modules/reports/components/ReportData.vue'
import { useProjectStore } from "@/modules/projects/store/projectStore";

const reportsStore = useReportsStore()
const projectStore = useProjectStore()
const { currentReport } = storeToRefs(reportsStore)
const route = useRoute()
currentReport.value.project_id = route.params.id as string

const projectEmployees = computed(() => {
  return projectStore.currentProject?.relationships?.employees || []
})
</script>

<route lang="yaml">
name: Project team report
</route>
