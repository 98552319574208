<template>
  <div>
    <div class="flex flex-wrap items-center w-full justify-between"
    >
      <PageTitle
        class="mb-2"
        :show-back="true"
        :loading="currentEvaluationLoading"
      >
        {{ evaluationName }}
      </PageTitle>
      <div class="flex flex-1 justify-end mb-2">
        <EditButton
            v-if="$can('manageEvaluations')"
            @click="goToEdit"
        />
      </div>
    </div>

    <BaseContentCard v-if="currentEvaluation.id">
      <ContentCardRow :title="$t('Evaluation date')">
        <div class="flex space-x-3 items-center text-sm">
          <CalendarIcon class="w-4 h-4 text-primary"/>
          <span>{{ $formatDate(currentEvaluation.attributes.evaluated_at) }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow
        v-if="currentEvaluation.attributes.next_evaluation_at"
        :title="$t('Next evaluation')">
        <div class="flex space-x-3 items-center text-sm">
          <CalendarIcon class="w-4 h-4 text-primary"/>
          <span>{{ $formatDate(currentEvaluation.attributes.next_evaluation_at) }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Type')">
        <div class="flex space-x-3 items-center text-sm">
          <EvaluationTypeLink :params="{ value: currentEvaluation.attributes.type_id}"/>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Assignees')">
        <EmployeeTags
          v-if="currentEvaluation.attributes.assignees.length"
          :employee-ids="currentEvaluation.attributes.assignees"
        />
        <div v-else class="text-sm">{{ $t('No assignees') }}</div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Salary')">
        <HiddenDetails class="text-sm">
          <div class="flex items-center space-x-2 text-sm">
            <div class="flex space-x-3 items-center">
              <BanknotesIcon class="w-4 h-4 text-primary"/>
              <span>{{ formatSalary('salary_before') }}</span>
            </div>
            <div class="w-8 flex justify-center">
              <ArrowRightIcon class="w-3 h-3"/>
            </div>
            <div class="flex space-x-3 items-center">
              <span>{{ formatSalary('salary_after') }}</span>
            </div>
          </div>
        </HiddenDetails>
      </ContentCardRow>
      <ContentCardRow :title="$t('Position')">
        <div class="flex items-center space-x-2 text-sm">
          <div class="flex space-x-3 items-center">
            <AcademicCapIcon class="w-4 h-4 text-primary"/>
            <span>{{ getPositionLabel(currentEvaluation.attributes.position_before_id) }}</span>
          </div>
          <div class="w-8 flex justify-center">
            <ArrowRightIcon class="w-3 h-3"/>
          </div>
          <div class="flex space-x-3 items-center">
            <span>{{ getPositionLabel(currentEvaluation.attributes.position_after_id) }}</span>
          </div>
        </div>
      </ContentCardRow>
    </BaseContentCard>
    <BaseContentCard
      v-if="currentEvaluation.id"
      class="mt-3 relative">
      <ContentCardRow :title="$t('Evaluation Link')">
        <div class="flex space-x-3 items-center text-sm">
          <LinkIcon class="w-4 h-4 text-primary"/>
          <a
            v-if="currentEvaluation.attributes.external_evaluation_url"
            :href="currentEvaluation.attributes.external_evaluation_url"
            rel="noopener"
            target="_blank">
            {{ currentEvaluation.attributes.external_evaluation_url }}
          </a>
          <span v-else>- -</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Notes')">
        <div class="flex space-x-3 items-center text-sm">
          <PencilIcon class="w-4 h-4 text-primary"/>
          <pre v-if="currentEvaluation.attributes.notes"
               class="font-sans prose prose-sm"
               v-html="currentEvaluation.attributes.notes"
          />
          <span v-else>- -</span>
        </div>
      </ContentCardRow>
    </BaseContentCard>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import {
  AcademicCapIcon,
  ArrowRightIcon,
  BanknotesIcon,
  CalendarIcon,
  LinkIcon,
  PencilIcon
} from "@heroicons/vue/24/outline";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useEvaluationStore } from "@/modules/evaluations/store/evaluationStore";
import PageTitle from "@/components/common/PageTitle.vue";
import EmployeeTags from "@/components/data/EmployeeTags.vue";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { formatPrice } from "@/plugins/formatPrice";
import EvaluationTypeLink from "@/components/table/cells/EvaluationTypeLink.vue";
import EditButton from "@/components/common/buttons/EditButton.vue";

const evaluationStore = useEvaluationStore()
const { evaluationName, currentEvaluationLoading, currentEvaluation } = storeToRefs(evaluationStore)
const { t } = useI18n()
const router = useRouter();

const settingsStore = useSettingsStore()
const { positions } = storeToRefs(settingsStore)

function getPositionLabel(id: string) {
  return positions.value.find(p => p.id === id)?.attributes.name ?? '- -'
}

function formatSalary(key: string) {
  const { currency } = currentEvaluation.value.attributes || {}
  const salary = currentEvaluation.value.attributes[key]
  return formatPrice(salary, { currency })
}

const route = useRoute()

function goToEdit() {
  const path = route.fullPath.replace('view', 'edit')
  router.push({ path })
}
</script>
