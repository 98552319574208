import { defineStore } from 'pinia'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfQuarter from 'date-fns/startOfQuarter'
import endOfQuarter from 'date-fns/endOfQuarter'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import addWeeks from 'date-fns/addWeeks'
import addMonths from 'date-fns/addMonths'
import addQuarters from 'date-fns/addQuarters'
import addYears from 'date-fns/addYears'
import { orderBy } from 'lodash-es'
import axios from 'axios'
import i18n from '@/i18n'
import { API_REPORT_DATE_FORMAT, formatDate } from '@/modules/common/utils/dateUtils'
import Data = API.Data
import { subMonths } from "date-fns"
import { getIntervalDetails } from "@/modules/reports/enum/reportUtils"

export const IntervalTypes = {
  Week: 'week',
  Month: 'month',
  LastMonth: 'last_month',
  Quarter: 'quarter',
  LastQuarter: 'last_quarter',
  Year: 'year',
  AllTime: 'all_time',
  Custom: 'custom',
}

export const ReportTypes = {
  Projects: 'projects',
  Tasks: 'tasks',
  Team: 'team',
  PresenceSheet: 'presenceSheet',
}

export interface ReportItem {
  entity_id: string
  employee_id: string
  entity_name: string
  total_billed_rate: string | null
  total_worked_minutes: string | null
}
export const useReportsStore = defineStore('reports', {
  state: () => {
    return {
      currentReport: {
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
        title: i18n.t('Month'),
        intervalType: IntervalTypes.Month,
        reportType: ReportTypes.Team,
        data: [] as Data<ReportItem>[],
        employee_id: null as string | null,
        client_id: null as string | null,
        project_id: null as string | null,
        task_id: null as string | null,
        meta: {
          total: 1,
          per_page: 15,
          current_page: 1,
          total_worked_hours: 0,
          total_billed_amount: 0,
        },
      },
      customRange: {
        saved: false,
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
      },
      currentReportLoading: false,
    }
  },
  actions: {
    async downloadReportAsPdf(path: string, filter: { label: string; key: string; value: any }[]) {
      const mappedFilter: Record<string, any> = {}

      filter.forEach((item) => {
        mappedFilter[item.key] = item.value
      })

      await axios.post('/restify/timesheets/actions?action=download-pdf-report', {
        path,
        report_filter: mappedFilter,
      })
    },
    resetIdFilters() {
      this.currentReport.client_id = null
      this.currentReport.employee_id = null
      this.currentReport.project_id = null
      this.currentReport.task_id = null
    },
    async nextInterval() {
      this.setRangeInterval(1)
      await this.getCurrentReport()
    },
    async previousInterval() {
      this.setRangeInterval(-1)
      await this.getCurrentReport()
    },
    changeReportRange() {
      const type = this.currentReport.intervalType
      const reportDetails = getIntervalDetails(type)

      if (reportDetails) {
        this.currentReport.from = reportDetails.from
        this.currentReport.to = reportDetails.to
        this.currentReport.title = reportDetails.title
      } else {
        this.currentReport.from = this.customRange.from
        this.currentReport.to = this.customRange.to
        this.currentReport.title = i18n.t('Custom')
      }
    },
    setRangeInterval(count = 1) {
      const type = this.currentReport.intervalType
      if (type === IntervalTypes.Week) {
        this.currentReport.from = addWeeks(this.currentReport.from, count)
        this.currentReport.to = addWeeks(this.currentReport.to, count)
      } else if (type === IntervalTypes.Month) {
        this.currentReport.from = addMonths(this.currentReport.from, count)
        this.currentReport.to = endOfMonth(this.currentReport.from)
      } else if (type === IntervalTypes.Quarter) {
        this.currentReport.from = addQuarters(this.currentReport.from, count)
        this.currentReport.to = endOfQuarter(this.currentReport.from)
      } else if (type === IntervalTypes.Year) {
        this.currentReport.from = addYears(this.currentReport.from, count)
        this.currentReport.to = endOfYear(this.currentReport.from)
      }
    },
    resetCurrentReport() {
      this.currentReport.data = []
    },
    async getCurrentReport() {
      const urlMap = {
        [ReportTypes.Projects]: '/restify/timesheets',
        [ReportTypes.Tasks]: '/restify/timesheets',
        [ReportTypes.Team]: '/restify/timesheets',
        [ReportTypes.PresenceSheet]: '',
      }
      const groupMap = {
        [ReportTypes.Projects]: 'project_id',
        [ReportTypes.Tasks]: 'task_id',
        [ReportTypes.Team]: 'employee_id',
      }
      const url = urlMap[this.currentReport.reportType]
      if (!url) {
        return
      }
      try {
        this.currentReportLoading = true
        const from = formatDate(this.currentReport.from, API_REPORT_DATE_FORMAT)
        const to = formatDate(this.currentReport.to, API_REPORT_DATE_FORMAT)
        const reportType = this.currentReport.reportType
        const { data, meta } = await axios.get(url, {
          params: {
            date: `${from},${to}`,
            client_id: this.currentReport.client_id,
            project_id: this.currentReport.project_id,
            task_id: this.currentReport.task_id,
            employee_id: this.currentReport.employee_id,
            group: groupMap[reportType],
            perPage: 200,
          },
        })
        this.currentReport.meta = meta
        this.currentReport.data = data.map((row: any) => {
          if (!row.id) {
            row.id = crypto.randomUUID()
          }
          return row
        })
        this.currentReport.data = orderBy(this.currentReport.data, 'attributes.entity_name')
        if (this.currentReport.reportType === ReportTypes.Tasks || this.currentReport.project_id) {
          this.currentReport.data = orderBy(this.currentReport.data, 'attributes.total_worked_minutes', 'desc')
        }
      } finally {
        this.currentReportLoading = false
      }
    },
  },
  getters: {
    currentReportTotal(state) {
      return state.currentReport.meta?.total_worked_hours || 0
    },
    currentReportTotalCost(state) {
      return state.currentReport.meta?.total_billed_amount || 0
    },
  },
})
