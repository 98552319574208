import { get } from 'lodash-es'
import { reactive, ref, watch } from 'vue'
import axios from "axios"
import { useRoute, useRouter } from "vue-router"
import Cache from '../Cache'

interface PaginatedRequestConfig {
  url: string | undefined
  appendData?: boolean
  urlParams?: any
  page?: number
  perPage?: number
  transformData?: Function
  defaultFilters?: boolean
  syncUrl?: boolean
  gridApi?: any
  gridContext?: any
}

function getRouteQuery({ params, route }: any) {
  return {
    ...route.query,
    ...params,
    search: params.search,
    pagination: params.pagination,
    perPage: params.perPage,
    total: undefined,
    last_page: undefined,
    related: undefined,
    active_employee: undefined,
    force: undefined,
    temporary_urls: undefined,
    employee_id: undefined,
    withMeta: undefined,
  }
}

async function replaceRouteQuery({ params, route, router }: any) {
  await router.replace({
    path: route.path,
    query: getRouteQuery({ params, route }),
  })
}

export function usePaginatedRequest(config: PaginatedRequestConfig) {
  const route = useRoute()
  const router = useRouter()
  const { query } = route || {}

  const paginatedData = ref([])
  const loading = ref<boolean>(false)
  const allParams = ref<any>({})
  const metaData = ref({})

  const pagination = reactive({
    page: Number(config?.urlParams?.page || query.page || 1),
    perPage: Number(config?.urlParams?.perPage || query.perPage || 15),
    total: 15,
    last_page: 1,
  })
  config.urlParams = {
    ...query,
    ...config.urlParams,
  }

  watch(() => pagination.page, async (newValue) => {
    await fetchData({ page: newValue })
  })

  watch(() => pagination.perPage, async (newValue) => {
    await fetchData({
      page: 1,
      perPage: newValue,
    })
  })

  async function getFilters() {
    if (!config.url || !config.gridContext.filtersUrl) {
      return
    }
    try {
      const url = config.gridContext.filtersUrl.replace('/api', '')
      const filterParams = {
        include: 'matches,sortables',
      }
      const result = await Cache.getRequest(url, {
        ttl: 60 * 60 * 1000, // 1 hour
        params: filterParams,
      })
      config.gridContext.filterOptions = {
        sortables: [],
        matches: [],
      }
      config.gridContext.lastRequestParams = allParams

      result.data.forEach((filter: any) => {
        if (filter.type === 'sortables' || filter.key === 'sortables') {
          config.gridContext.filterOptions.sortables.push(filter)
        } else {
          config.gridContext.filterOptions.matches.push(filter)
        }
      })
    } catch (err) {
      console.warn('Could not retrieve filters')
    }
  }

  async function fetchData(requestParams: any = {}) {
    if (!config.url) {
      config.gridApi?.value?.hideOverlay()
      return
    }
    try {
      config.gridApi?.value?.showLoadingOverlay()
      loading.value = true

      if (requestParams?.search) {
        pagination.page = 1
      }

      allParams.value = {
        search: allParams.value.search,
        ...config.urlParams,
        ...pagination,
        ...requestParams,
      }

      let response: any
      if (requestParams.force) {
        response = await axios.get(config.url, {
          params: allParams.value,
        })
      } else {
        response = await Cache.getRequest(config.url, {
          params: allParams.value,
        })
      }

      let data: [] = get(response, 'data', [])

      if (config.transformData) {
        data = config.transformData(data)
      }

      if (pagination.page > 1 && config.appendData) {
        paginatedData.value.push(...data)
      } else {
        paginatedData.value = data
      }

      pagination.page = response?.meta?.current_page
      pagination.perPage = response?.meta?.per_page
      pagination.total = response?.meta?.total
      pagination.last_page = response?.meta?.last_page

      metaData.value = response.meta

      if (config.syncUrl) {
        await replaceRouteQuery({
          params: allParams.value,
          route,
          router,
        })
      }
      const { links } = response
      if (links?.filters && config.gridContext) {
        config.gridContext.filtersUrl = links.filters
        await getFilters()
      }
    } catch (err) {
      console.error('Could not fetch the data', err)
    } finally {
      loading.value = false
      config.gridApi?.value?.hideOverlay()
    }

    return paginatedData
  }

  return {
    loading,
    fetchData,
    data: paginatedData,
    requestParams: allParams,
    pagination,
    metaData,
  }
}
