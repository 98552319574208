<template>
  <div>
    <BaseDataTable
      :data-loading="currentReportLoading"
      :columns="columns"
      :data="reportData"
      :pagination="false"
      :row-height="55"
      dom-layout="autoHeight"
    >
      <template v-if="reportType === ReportTypes.Tasks" #header-actions-right>
        <TasksBarChart :data="currentReport.data" />
      </template>
      <template #name="{ row, index }">
        <EmployeeLink
          v-if="reportType === ReportTypes.Team"
          :to="getReportLink(row)"
          :params="{ value: row?.attributes?.entity_id }"
        />
        <router-link
          v-else
          :to="getReportLink(row)" class="no-underline text-gray-900 flex items-center space-x-2"
        >
          <div
            v-if="reportType === ReportTypes.Tasks"
            :key="index"
            :style="{ backgroundColor: getTaskColor(index) }"
            class="rounded min-w-[16px] w-4 h-4"
          />
          <span>{{ row?.attributes?.entity_name }}</span>
          <Status v-if="row?.attributes?.archived" :params="{ value: 'archived' }"/>
        </router-link>
      </template>

      <template #total_minutes="{ row }">
        <span v-if="row.attributes.no_logs" class="text-gray-500">
          {{ formatHours(row) }}
        </span>
        <router-link v-else :to="getDetailedLink(row)">
          {{ formatHours(row) }}
        </router-link>
      </template>
    </BaseDataTable>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core'
import { ReportItem, ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { useClientStore } from '@/modules/clients/store/clientStore'
import { API_REPORT_DATE_FORMAT, formatDate } from '@/modules/common/utils/dateUtils'
import { getTaskColor } from '@/modules/reports/enum/reportEnums'
import TasksBarChart from '@/modules/reports/components/TasksBarChart.vue'
import EmployeeLink from '@/components/table/cells/EmployeeLink.vue'
import { isMobile } from "@/util/responsiveUtils"
import { isBetaTestingCompany } from "@/modules/common/config"
import { formatPrice } from "@/plugins/formatPrice"
import { can } from "@/plugins/permissionPlugin"
const props = defineProps({
  reportType: {
    type: String,
    default: ReportTypes.Team,
  },
  showEmployeesWithoutLogs: {
    type: Boolean,
    default: false,
  },
  employees: {
    type: Array as PropType<Data<Employee>[]>,
    default: () => [],
  },
})

import Data = API.Data
import Employee = App.Domains.Employees.Models.Employee
import Timesheet = App.Domains.TimeSheets.Models.Timesheet;
import { formatHours } from "../utils/reportUtils";

const { t } = useI18n()

const reportStore = useReportsStore()
const employeeStore = useEmployeeStore()
const { currentReport, currentReportLoading } = storeToRefs(reportStore)
reportStore.currentReport.reportType = props.reportType

const reportData = computed(() => {
  if (!props.showEmployeesWithoutLogs) {
    return currentReport.value.data
  }
  const reportEmployeeIds = currentReport.value.data.map((e: any) => e.attributes.employee_id)
  const difference = props.employees.filter(x => !reportEmployeeIds.includes(x.id))
  const employeesWithoutLogs = difference.map((employee: Data<Employee>) => {
    return {
      id: employee.id,
      attributes: {
        entity_id: employee.id,
        name: employee?.attributes?.name,
        total_minutes: 0,
        total_billed_rate: 0,
        no_logs: true,
      },
    }
  })
  return [...currentReport.value.data, ...employeesWithoutLogs]
})
const hoursColumn: ColDef = {
  headerName: t('Hours'),
  field: 'total_minutes',
  type: 'rightAligned',
  cellClass: 'font-medium text-right',
  headerClass: 'ag-right-aligned-header',
  resizable: false,
  sortable: true,
  sortingOrder: ['desc', 'asc', null],
  minWidth: isMobile() ? 140 : 200,
  valueGetter: (params: ValueGetterParams) => {
    return +params.data?.attributes?.total_worked_minutes
  },
}
const costColumn: ColDef = {
  headerName: t('Billable amount'),
  field: 'attributes.total_billed_rate',
  type: 'rightAligned',
  cellClass: (params: CellClassParams) => {
    const { no_logs } = params.data?.attributes || {}
    if (no_logs) {
      return 'text-gray-500 text-right'
    }
    return 'font-bold text-right'
  },
  headerClass: 'ag-right-aligned-header',
  resizable: false,
  sortable: true,
  minWidth: 200,
  sortingOrder: ['desc', 'asc', null],
  hide: !can('manageInvoices'),
  valueFormatter: (params: ValueFormatterParams) => {
    let value = params.value
    if (params.value === null || params.value === undefined) {
      value = 0
    }
    return formatPrice(value, {
      maximumFractionDigits: 2,
    })
  },
  valueGetter: (params: ValueGetterParams) => {
    return +params.data?.attributes?.total_billed_rate
  },
}

const route = useRoute()
function getReportLink(row: Data<ReportItem>) {
  const query = getDateQueryParams()

  if (props.reportType === ReportTypes.Projects) {
    return {
      path: `/reports/projects/${row.attributes?.entity_id}/tasks`,
      query,
    }
  }
  if (props.reportType === ReportTypes.Team) {
    return {
      path: `/reports/team/${row.attributes?.entity_id}/projects`,
      query,
    }
  }
  return getDetailedLink(row)
}

function getDateQueryParams() {
  const start_date = formatDate(reportStore.currentReport.from, API_REPORT_DATE_FORMAT)
  const end_date = formatDate(reportStore.currentReport.to, API_REPORT_DATE_FORMAT)
  return {
    start_date,
    end_date,
  }
}

const projectStore = useProjectStore()
function getDetailedLink(row: Data<ReportItem>) {
  if (!row.attributes) {
    return route
  }
  const queryParams: any = getDateQueryParams()
  if (route.path.startsWith('/reports/team')) {
    queryParams.employee_id = route.params.id
  } else if (route.path.startsWith('/reports/projects')) {
    queryParams.project_id = route.params.id
    queryParams.client_id = projectStore.getProjectClient(queryParams.project_id)
  }
  if (props.reportType === ReportTypes.Team) {
    queryParams.employee_id = row.attributes.entity_id
  } else if (props.reportType === ReportTypes.Tasks) {
    queryParams.task_id = row.attributes.entity_id
  } else if (props.reportType === ReportTypes.Projects) {
    queryParams.project_id = row.attributes.entity_id
    const client_id = projectStore.getProjectClient(queryParams.project_id)
    queryParams.client_id = client_id
  }
  if (currentReport.value.intervalType) {
    queryParams.interval_type = currentReport.value.intervalType
  }
  return {
    path: '/reports/detailed',
    query: queryParams,
  }
}

const nameColumn: ColDef = {
  headerName: t('Name'),
  field: 'name',
  minWidth: isMobile() ? 180 : 200,
  sortable: true,
  resizable: false,
}
const employeeColumns = ref<ColDef[]>([
  nameColumn,
  hoursColumn,
  costColumn,
])

const tasksColumns = ref<ColDef[]>([
  nameColumn,
  hoursColumn,
  costColumn,
])

const projectsColumns = ref<ColDef[]>([
  nameColumn,
  {
    hide: true,
    headerName: t('Client'),
    field: 'entity_id',
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      const projectId = params.value
      const project = useProjectStore().getProjectById(projectId)
      if (!project) {
        return ''
      }
      const client = useClientStore().getClientById(project.attributes.client_id)
      return client?.attributes?.company_name || ''
    },
  },
  hoursColumn,
  costColumn,
])

const columns = computed(() => {
  if (props.reportType === ReportTypes.Team) {
    return employeeColumns.value
  } else if (props.reportType === ReportTypes.Tasks) {
    return tasksColumns.value
  } else if (props.reportType === ReportTypes.Projects) {
    return projectsColumns.value
  }
  return []
})

onMounted(() => {
  reportStore.getCurrentReport()
})
onBeforeMount(() => {
  reportStore.resetCurrentReport()
})
</script>
