import { DirectiveBinding } from "vue";

export default {
  name: 'focus',
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.hasOwnProperty('value') && binding.value === false) {
      return;
    }

    const input: HTMLInputElement | null = el.querySelector('input')
    const textarea: HTMLTextAreaElement | null = el.querySelector('textarea')
    const select: HTMLSelectElement | null = el.querySelector('select')
    const button: HTMLButtonElement | null = el.querySelector('button')
    let elements = [input, textarea, select, button]
    const focusableElement = elements.find(el => el && el.focus)

    const elementToFocus = focusableElement || el
    const delay = binding.value || 0
    
    setTimeout(() => {
      elementToFocus?.focus()
    }, delay)
  },
}
