<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="options"
    :data-loading="allProjectsLoading || employeeProjectsLoading"
    :has-groups="can('manageClients')"
    value-key="id"
    label-key="attributes.name"
    filterable
    default-first-option
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { useFormKitInput } from '@/components/formkit/useFormkitInput'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { useAuth } from '@/modules/auth/composables/useAuth'
import Data = API.Data;
import { useClientStore } from "@/modules/clients/store/clientStore"
import { groupBy } from "lodash-es"

const props = defineProps({
  context: Object,
})
const projectStore = useProjectStore()
const { allProjects, employeeProjects, employeeProjectsLoading, allProjectsLoading } = storeToRefs(projectStore)
const clientStore = useClientStore()

const { value, allAttrs, handleChange } = useFormKitInput(props)

const employeeId = computed(() => {
  return allAttrs.value['employee-id'] as string
})

const clientId = computed(() => {
  return allAttrs.value['client-id'] as string
})
const { userEmployeeId } = useAuth()
const isOwnEmployee = computed(() => {
  return employeeId.value === userEmployeeId.value
})

const { can } = useAuth()
const filterByEmployee = computed(() => {
  return (isOwnEmployee.value || employeeId.value) && can('manageProjects')
})

const projects = computed(() => {
  if (filterByEmployee.value) {
    return employeeProjects.value[employeeId.value] || []
  }
  return allProjects.value.filter((project: Data<any>) => {
    if (clientId.value) {
      return project.attributes.client_id === clientId.value
    }
    return true
  })
})

const options = computed(() => {
  if (!can('manageClients')) {
    return projects.value
  }
  const groups = groupBy(projects.value, 'attributes.client_id')
  const groupOptions = []
  for (const clientId in groups) {
    const project = groups[clientId][0] as any
    const clientName = project?.attributes?.client_name ?? ''
    groupOptions.push({
      label: clientName,
      options: groups[clientId],
    })
  }
  return groupOptions
})
onMounted(async () => {
  if (filterByEmployee.value) {
    await projectStore.getEmployeeProjects(employeeId.value)
  } else {
    await projectStore.getAllProjects()
  }
})
</script>
