<template>
  <div class="flex flex-col sm:flex-row space-x-2">
    <div class="space-y-2 mb-4 flex flex-1 flex-col">
      <BaseContentCard :title="$t('Project details')">
        <ContentCardRow :title="$t('Client')">
          <router-link :to="`/clients/${projectClient.id}/details`" class="text-gray-900 no-underline">
            {{ projectClient?.attributes.company_name }}
          </router-link>
        </ContentCardRow>
        <ContentCardRow :title="$t('Name')">
          {{ currentProject.name }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Description')">
          <div class="prose break-words" v-html="currentProject.description" />
        </ContentCardRow>
      </Basecontentcard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import BaseContentCard from '@/components/common/BaseContentCard.vue'
import ContentCardRow from '@/components/common/ContentCardRow.vue'
import { useAuthStore } from '@/modules/auth/store/authStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import Client = App.Domains.Clients.Models.Client
import Data = API.Data

const { t } = useI18n()

const projectStore = useProjectStore()
const currentProject = computed<any>(() => {
  return projectStore.currentProject
})

const projectClient = computed<Data<Client>>(() => {
  return currentProject.value?.relationships?.client
})

const authStore = useAuthStore()
const { profile } = storeToRefs(authStore)
</script>
