import { useAuth } from "@/modules/auth/composables/useAuth";
import ObjectValues = API.ObjectValues;

export const EventTypes = {
  CreateAccountStarted: 'Create Account Started',
  AccountVerified: 'Account Verified',
  OnboardingStep1: 'Onboarding Step 1',
  OnboardingStep2: 'Onboarding Step 2',
  OnboardingStep3: 'Onboarding Step 3',
  OrgCreated: 'Org Created',
  InviteSent: 'Invite Sent',
  EmployeeCreated: 'Employee Created',
  HolidayCreated: 'Holiday Created',
  ProjectCreated: 'Project Created',
  SubscriptionStarted: 'Subscription Started',
  SubscriptionUpgraded: 'Subscription Upgraded',
  SubscriptionCancelled: 'Subscription Cancelled',
} as const

export const EventLocations = {
  Sidebar: 'Sidebar',
  Header: 'Header',
  Table: 'table',
  Form: 'form',
  General: 'general',
} as const

export const EventCategories = {
  Signup: 'Signup',
  General: 'General',
  Engagement: 'Engagement',
  Subscription: 'Subscription',
  Onboarding: 'Onboarding',
} as const

interface EventParams {
  action: ObjectValues<typeof EventTypes>
  location: ObjectValues<typeof EventLocations> | string
  category: ObjectValues<typeof EventCategories> | string
  label: string | null
  value?: number

  [key: string]: any
}

export function event({ action, location, label, category, ...rest }: EventParams) {
  // @ts-expect-error
  if (!window?.analytics) {
    return
  }
  // @ts-expect-error
  window?.analytics?.track(action, {
    location,
    category,
    pathname: window.location.pathname,
    label,
    ...rest,
  })
}

export function trackCreateAccountStarted() {
  event({
    action: EventTypes.CreateAccountStarted,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Onboarding,
  })
}

export function trackOnboardingStep(step: number, data = {}) {
  const additionalData: any = {
    ...data,
  }
  delete additionalData.password
  const stepMap: Record<number, string> = {
    1: EventTypes.OnboardingStep1,
    2: EventTypes.OnboardingStep2,
    3: EventTypes.OnboardingStep3,
  }
  const action = stepMap[step]
  event({
    action: action as any,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Onboarding,
    ...additionalData,
  })
  if (step === 3) {
    event({
      action: EventTypes.OrgCreated,
      location: window.location.pathname,
      label: EventLocations.General,
      category: EventCategories.Onboarding,
      ...additionalData,
    })
  }
}

export function trackEmployeeCreated(data = {}) {
  event({
    action: EventTypes.EmployeeCreated,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Engagement,
    ...data,
  })
}

export function trackInvitationSent(data = {}) {
  event({
    action: EventTypes.InviteSent,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Engagement,
    ...data,
  })
}

export function trackHolidayCreated(data = {}) {
  event({
    action: EventTypes.HolidayCreated,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Engagement,
    ...data,
  })
}

export function trackProjectCreated(data = {}) {
  event({
    action: EventTypes.ProjectCreated,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Engagement,
    ...data,
  })
}

export function trackSubscriptionUpgraded(data = {}) {
  event({
    action: EventTypes.SubscriptionUpgraded,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Subscription,
    ...data,
  })
}

export function trackSubscriptionCancelled(data = {}) {
  event({
    action: EventTypes.SubscriptionCancelled,
    location: window.location.pathname,
    label: EventLocations.General,
    category: EventCategories.Subscription,
    ...data,
  })
}

export function useAnalytics() {
  const { user, tenant } = useAuth()

  function identifyHotjarUser() {
    // @ts-expect-error
    if (!window.hj || !user.value.id) {
      return
    }
    // @ts-expect-error
    window.hj('identify', user.value.id, {
      ...user.value,
    })
  }

  function identifySegmentUser() {
    const { id, first_name, last_name, roles, status, email } = user.value as any
    const roleNames = roles?.map((role: any) => role?.attributes.name).join(', ')
    // @ts-expect-error
    if (!window.analytics) {
      return
    }
    // @ts-expect-error
    window.analytics.identify(id, {
      first_name,
      last_name,
      status,
      email,
      roles: roleNames,
      tenant: tenant.value,
    })
  }

  function identifyUser() {
    identifyHotjarUser()
    identifySegmentUser()
  }

  return {
    identifyUser,
  }
}
