<template>
  <FormKit
    type="form"
    :actions="false"
    @submit="onSubmit"
  >
    <ElDialog v-bind="$attrs">
      <div v-focus>
        <FormKit
          v-model="model.name"
          :label="t('Name')"
          :placeholder="t('Holiday')"
          type="text"
          validation="required|length:0,50"
          outer-class="col-span-3"
        />
      </div>
      <FormKit
        v-model="model.color"
        type="colorpicker"
        :label="$t('Color')"
        :options="colorOptions"
        panel-controls="false"
        panel-format="false"
        inline
      />

      <template #footer>
        <div class="w-full flex justify-center space-x-2">
          <FormKit
            type="button"
            :classes="{ input: 'min-w-[128px] btn-outline' }"
            @click="emit('close')"
          >
            <span>{{ t('Cancel') }}</span>
          </FormKit>
          <FormKit
            type="submit"
            :classes="{ input: 'min-w-[128px]' }"
            :disabled="loading"
          >
            <span>{{ t('Save') }}</span>
          </FormKit>
        </div>
      </template>
    </ElDialog>
  </FormKit>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import axios from "axios"
import { useI18n } from "vue-i18n"
import theme from 'tailwindcss/defaultTheme'
import { error, success } from "@/components/common/NotificationPlugin"
import { TagColors } from "@/modules/settings/types/settingTypes";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['save', 'close'])
const loading = ref(false)
const model = ref({
  name: '',
  color: '#149ea3',
})

const colorOptions = computed(() => {
  return [
    {
      label: 'primary',
      value: TagColors.Primary,
    },
    {
      name: "blue",
      value: TagColors.Blue,
    },
    {
      name: "yellow",
      value: TagColors.Yellow,
    },
    {
      name: "orange",
      value: TagColors.Orange,
    },
    {
      name: "red",
      value: TagColors.Red,
    },
    {
      name: "green",
      value: TagColors.Green,
    },
    {
      name: "violet",
      value: TagColors.Violet,
    },
    {
      name: "magenta",
      value: TagColors.Magenta,
    },
  ]
})
watch(() => props.data, (value) => {
  if (!value?.attributes) {
    return
  }
  model.value = {
    id: value.id,
    name: value.attributes?.name,
    color: value.attributes?.color,
  }
}, { immediate: true })

const { t } = useI18n()
async function onSubmit() {
  try {
    const { id } = props.data
    let response
    if (id) {
      response = await axios.put(`/restify/tags/${id}`, model.value)
    } else {
      response = await axios.post(`/restify/tags`, model.value)
    }
    success(t('Tag saved'))
    emit('save', response.data)
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not save the tag'))
  }
}
</script>
