import { computed, ref } from "vue"
import { UserIcon, UsersIcon } from "@heroicons/vue/24/outline"
import { useRoute } from "vue-router"
import { PermissionUnion, can } from "@/plugins/permissionPlugin"
import i18n from "@/i18n"
import { useAuth } from "@/modules/auth/composables/useAuth"

type TabPageTypes = 'evaluations' | 'holidays' | 'documents'

export function useFilterTabs(entity: TabPageTypes) {
  const data = PageEntities[entity] || PageEntities.default as PageEntity
  const activeTab = ref(i18n.t('My'))

  const myTab = {
    label: i18n.t('My'),
    path: `${data.path}/my`,
    icon: UserIcon,
  }

  const allTab = {
    label: i18n.t('All'),
    path: `${data.path}/all`,
    icon: UsersIcon,
  }
  const canManage = can(data.permission)
  if (canManage) {
    activeTab.value = i18n.t('All')
  }
  const tabs = computed(() => {
    const tabs = []
    if (canManage) {
      tabs.push(allTab)
    }
    tabs.push(myTab)
    return tabs
  })

  const activeTabPath = computed(() => {
    return tabs.value.find(tab => tab.label === activeTab.value)?.path || myTab.path
  })

  const route = useRoute()
  const { userEmployeeId } = useAuth()

  function getSaveRedirectPath() {
    const { employee_id } = route.query
    if (employee_id && employee_id !== userEmployeeId.value) {
      return `/employees/${employee_id}/${entity}`
    }
    return `${activeTabPath.value}`
  }

  return {
    tabs,
    activeTab,
    getSaveRedirectPath,
    activeTabPath,
  }
}

interface PageEntity {
  path: string
  permission: PermissionUnion | ''
}
interface PageEntitiesType {
  [key: string]: PageEntity
}
export const PageEntities = {
  evaluations: {
    path: '/evaluations',
    permission: 'manageEvaluations',
  },
  holidays: {
    path: '/holidays',
    permission: 'manageHolidays',
  },
  documents: {
    path: '/documents',
    permission: 'manageDocuments',
  },
  default: {
    path: '',
    permission: '',
  },
} as PageEntitiesType
