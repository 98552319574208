<template>
  <SettingsCrudPage
    ref="table"
    :title="$t('Company positions')"
    :modal-component="PositionModal"
    :add-text="$t('Add position')"
    :edit-text="$t('Edit position')"
    :url-params="urlParams"
    :groupDefaultExpanded="-1"
    :suppressAggFuncInHeader="true"
    :columns="columns"
    groupDisplayType="groupRows"
    entity="positions"
  />
</template>
<script setup lang="ts">
import PositionModal from "@/modules/settings/components/PositionModal.vue";
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { ColDef, ValueSetterParams } from "@ag-grid-community/core";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";

const urlParams = {
  related: 'department',
  sort: 'department.name',
}
const table = ref()
const settingsStore = useSettingsStore()
const { t } = useI18n()
const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.name',
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.name = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
  {

    headerName: t('Description'),
    field: 'attributes.description',
    editable: true,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.description = params.newValue
      table.value?.updateRow(params.data)
      return true
    }
  },
])

</script>
<route lang="yaml">
name: Positions
meta:
  permission: managePositions
</route>
