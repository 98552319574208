import { generateClasses } from '@formkit/themes'
import { DefaultConfigOptions, createInput } from '@formkit/vue'
import { createProPlugin, inputs as proInputs } from "@formkit/pro"
import { en, ro } from '@formkit/i18n'
import FormKitSelect from './src/components/formkit/FormKitSelect.vue'
import FormKitDatePicker from './src/components/formkit/FormKitDatePicker.vue'
import FormKitHtmlEditor from './src/components/formkit/FormKitHtmlEditor.vue'
import UserSelect from './src/components/autocomplete/UserSelect.vue'
import FormKitSwitch from './src/components/formkit/FormKitSwitch.vue'
import OneTimePassword from './src/components/formkit/OneTimePassword.vue'
import genesisTheme from './genesisTheme'
import { addAsterisksIfIsRequired } from '@/plugins/formKitRequiredLabel'
import CountrySelect from "@/components/formkit/CountrySelect.vue"
import TimeZoneSelect from "@/components/formkit/TimeZoneSelect.vue"
import PositionSelect from "@/components/formkit/PositionSelect.vue"
import DepartmentSelect from "@/components/formkit/DepartmentSelect.vue"
import DocumentTypesSelect from "@/components/formkit/DocumentTypesSelect.vue"
import ContractTypeSelect from "@/components/formkit/ContractTypeSelect.vue"
import EvaluationTypesSelect from "@/components/formkit/EvaluationTypesSelect.vue"
import EmployeeSelect from "@/components/formkit/EmployeeSelect.vue"
import CurrencySelect from "@/components/formkit/CurrencySelect.vue"
import HolidayPolicySelect from "@/components/formkit/HolidayPolicySelect.vue"
import RoleSelect from "@/components/formkit/RoleSelect.vue"
import { validationRules } from "@/modules/common/validation"
import GoogleCalendarSelect from "@/components/formkit/GoogleCalendarSelect.vue"
import LevelSelect from "@/components/formkit/LevelSelect.vue"
import ProjectSelect from "@/components/formkit/ProjectSelect.vue"
import ClientSelect from "@/components/formkit/ClientSelect.vue"
import ClientContactSelect from "@/components/formkit/ClientContactSelect.vue"
import TaskSelect from "@/components/formkit/TaskSelect.vue"
import { scrollToErrors } from "@/plugins/scrollToFormErrors"
import IndustrySelect from "@/components/formkit/IndustrySelect.vue";
import DocumentTagSelect from "@/components/formkit/DocumentTagSelect.vue";
import ReplyToSelect from '@/components/formkit/ReplyToSelect.vue'
const pro = createProPlugin('fk-3f1b2e6d11', proInputs)
const baseStyles = {
  outer: '$reset formkit-disabled:opacity-80 formkit-loading:loading formkit-outer-container',
  wrapper: '$reset',
  label: '$reset label pt-3 pb-2 text-sm text-base-300 font-medium px-0',
  inner: '$reset form-control relative group',
  input: '$reset input input-bordered formkit-disabled:bg-gray-50 formkit-disabled:text-base-content/80 border-input w-full formkit-invalid:input-error focus:outline-none focus:border-primary',
  help: 'text-xs text-gray-500 leading-4',
  messages: 'list-none p-0 mt-1 mb-0',
  message: 'text-red-500 text-xs',
  prefixIcon: '$reset absolute text-primary left-[16px] h-full w-[14px] flex items-center text-base-300 group-focus:text-primary',
  suffixIcon: '$reset absolute right-[14px] h-full w-[14px] flex items-center text-base-300 hover:text-primary hover:cursor-pointer',
}

const buttonStyles = {
  outer: baseStyles.outer,
  input: '$reset btn btn-primary',
}
const defaultLocale = localStorage.getItem('locale') || 'en'
const formKitConfig: DefaultConfigOptions = {
  locales: {
    en,
    ro: {
      ...ro,
      ui: {
        incomplete: 'Ne pare rău, formularul nu este completat corect',
      },
    },
  },
  locale: defaultLocale,
  inputs: {
    customSelect: createInput(FormKitSelect),
    customDatePicker: createInput(FormKitDatePicker),
    countrySelect: createInput(CountrySelect),
    timezoneSelect: createInput(TimeZoneSelect),
    userSelect: createInput(UserSelect),
    positionSelect: createInput(PositionSelect),
    projectSelect: createInput(ProjectSelect),
    industrySelect: createInput(IndustrySelect),
    clientSelect: createInput(ClientSelect),
    clientContactSelect: createInput(ClientContactSelect),
    taskSelect: createInput(TaskSelect),
    replyToSelect: createInput(ReplyToSelect),
    levelSelect: createInput(LevelSelect),
    departmentSelect: createInput(DepartmentSelect),
    roleSelect: createInput(RoleSelect),
    holidayPolicySelect: createInput(HolidayPolicySelect),
    googleCalendarSelect: createInput(GoogleCalendarSelect),
    documentTypeSelect: createInput(DocumentTypesSelect),
    documentTagSelect: createInput(DocumentTagSelect),
    contractTypeSelect: createInput(ContractTypeSelect),
    evaluationTypeSelect: createInput(EvaluationTypesSelect),
    employeeSelect: createInput(EmployeeSelect),
    currencySelect: createInput(CurrencySelect),
    switch: createInput(FormKitSwitch),
    html: createInput(FormKitHtmlEditor),
    otp: createInput(OneTimePassword, {
      props: ['digits'],
    }),
  },
  config: {
    classes: generateClasses({
      global: baseStyles,
      email: baseStyles,
      text: baseStyles,
      password: baseStyles,
      button: buttonStyles,
      checkbox: {
        ...baseStyles,
        input: '$reset checkbox checkbox-primary',
        label: '$reset label py-0 text-sm text-base-content pl-3',
        wrapper: 'flex items-center',
        decorator: 'hidden',
      },
      radio: {
        ...baseStyles,
        input: '$reset radio radio-primary',
        label: '$reset label py-0 text-sm text-base-300 pl-3',
        legend: '$reset label px-0 py-0 mb-4 text-sm text-base-300',
        wrapper: 'flex items-center',
        fieldset: '$reset',
        decorator: 'hidden',
      },
      submit: {
        ...buttonStyles,
        wrapper: 'flex justify-end',
      },
      datepicker: genesisTheme.datepicker,
    }),
  },
  iconLoaderUrl: (name) => {
    return `https://raw.githubusercontent.com/tailwindlabs/heroicons/master/src/24/outline/${name}.svg`
  },
  plugins: [addAsterisksIfIsRequired, pro, scrollToErrors],
  rules: validationRules,
}
export default formKitConfig
