<template>
  <FilterInput
    v-bind="$attrs"
    :data="employees"
    value-key="value"
    label-key="label"
  />
</template>
<script lang="ts" setup>
import FilterInput from "@/components/table/filters/base/FilterInput.vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";
const { t } = useI18n()

const employeeStore = useEmployeeStore()
const employees = computed(() => {
  return employeeStore.allEmployees.map((employee) => {
    const { first_name, last_name } = employee.attributes
    return {
      value: employee.id,
      label: `${first_name} ${last_name}`,
    }
  })
})
</script>
