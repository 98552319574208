import Status from './Status.vue'
import DocumentStatus from './DocumentStatus.vue'
import HolidayRequestStatus from './HolidayRequestStatus.vue'
import YesNoStatus from './YesNoStatus.vue'
import FileLink from './FileLink.vue'
import EmployeeLink from './EmployeeLink.vue'
import EmployeeTagsCell from './EmployeeTagsCell.vue'
import FormattedPrice from './FormattedPrice.vue'
import PositionLink from "@/components/table/cells/PositionLink.vue";
import FormattedDate from "@/components/table/cells/FormattedDate.vue";
import EvaluationTypeLink from "@/components/table/cells/EvaluationTypeLink.vue";

export const tableCellComponents: any = {
  Status,
  DocumentStatus,
  YesNoStatus,
  FileLink,
  EmployeeLink,
  PositionLink,
  EmployeeTagsCell,
  FormattedDate,
  FormattedPrice,
  HolidayRequestStatus,
  EvaluationTypeLink,
}

export enum ColumnTypes {
  Custom = 'custom',
  Price = 'FormattedPrice',
  Status = 'Status',
  DocumentStatus = 'DocumentStatus',
  HolidayRequestStatus = 'HolidayRequestStatus',
  YesNoStatus = 'YesNoStatus',
  FileLink = 'FileLink',
  EmployeeLink = 'EmployeeLink',
  PositionLink = 'PositionLink',
  EvaluationTypeLink = 'EvaluationTypeLink',
  EmployeeTags = 'EmployeeTagsCell',
  Date = 'FormattedDate',
}
