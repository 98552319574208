<template>
  <DragHandle
    plugin-key="ContentItemMenu"
    :editor="editor"
    :tippy-options="{
      offset: [-3, 5],
      zIndex: 99,
    }"
    @node-change="data.handleNodeChange"
  >
    <div class="flex items-center gap-0.5">
      <PopoverRoot v-model.open="menuOpen">
        <PopoverTrigger as-child>
          <ToolbarButton>
            <Icon name="GripVertical" />
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverContent side="bottom" align="start" :side-offset="8">
          <Surface class="p-2 flex flex-col min-w-[16rem]">
            <PopoverClose>
              <DropdownButton @click="actions.resetTextFormatting">
                <Icon name="RemoveFormatting" />
                {{ $t('Clear formatting') }}
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.copyNodeToClipboard">
                <Icon name="Clipboard" />
                {{ $t('Copy to clipboard') }}
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.duplicateNode">
                <Icon name="Copy" />
                {{ $t('Duplicate') }}
              </DropdownButton>
            </PopoverClose>
            <ToolbarDivider horizontal />
            <PopoverClose>
              <DropdownButton
                class="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
                @click="actions.deleteNode"
              >
                <Icon name="Trash2" />
                {{ $t('Delete') }}
              </DropdownButton>
            </PopoverClose>
          </Surface>
        </PopoverContent>
      </PopoverRoot>
    </div>
  </DragHandle>
</template>

<script setup lang="ts">
import { Editor } from "@tiptap/vue-3"
import { PopoverClose, PopoverContent, PopoverRoot, PopoverTrigger } from "radix-vue"
import { ref, watch } from "vue"
import { useData } from "./composables/useData"
import useContentItemActions from "./composables/useContentItemActions"
import DragHandle from "./DragHandle.vue"
import Icon from "@/components/formkit/html/components/Icon.vue"
import Surface from "@/components/formkit/html/components/Surface.vue"
import ToolbarButton from "@/components/formkit/html/components/toolbar/ToolbarButton.vue"
import ToolbarDivider from "@/components/formkit/html/components/toolbar/ToolbarDivider.vue"
import DropdownButton from "@/components/formkit/html/components/dropdown/DropdownButton.vue"

const props = defineProps({
  editor: Editor,
})
const data = useData()
const actions = useContentItemActions(props.editor as Editor)

const menuOpen = ref(false)

watch(() => menuOpen.value, () => {
  if (menuOpen.value) {
    props.editor?.commands.setMeta('lockDragHandle', true)
  } else {
    props.editor?.commands.setMeta('lockDragHandle', false)
  }
})
</script>
