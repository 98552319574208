import { computed, useAttrs } from "vue";

export function useFormKitInput(props: any) {
  const attrs = useAttrs()
  const allAttrs = computed(() => {
    return {
      ...attrs,
      ...(props?.context?.attrs || {}),
      id: props?.context?.id,
      name: props?.context?.node?.name,
      disabled: props.context.disabled,
    }
  })
  
  const value = computed(() => {
    let value = props?.context?._value
    if (!props.context) {
      value = attrs.modelValue
    }
    const isRange = allAttrs.value['picker-type'] === 'daterange'
    if (Array.isArray(value) || isRange) {
      return value ? Array.from(value) : []
    }
    return value?.toString()
  })

  function handleChange(val: any) {
    props.context?.node?.input(val)
  }
  return {
    value,
    allAttrs,
    handleChange,
  }
}
