<template>
  <div
    class="w-full base-select"
    :class="{
      'hide-input-value': isIdValue && !isOptionInList,
    }"
    :id="$attrs.id"
  >
    <ElSelect
      ref="select"
      v-bind="$attrs"
      v-model="model"
      class="w-full"
      :popper-class="popperClass"
      :filterable="$attrs.filterable"
      :remote="!!url"
      :remote-method="url ? remoteMethod : undefined"
      :default-first-option="!!(url ? fetchData : undefined) || $attrs['default-first-option']"
      :multiple="multiple"
      :loading="loading"
      :loading-text="$t('Loading...')"
      :placeholder="placeholder"
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="1"
      @focus="onFocus"
    >
      <template v-if="hasGroups">
        <ElOptionGroup
          v-for="group in selectOptions"
          :key="group.label"
          :label="group.label"
        >
          <ElOption
            v-for="item in group.options"
            :key="get(item, valueKey)"
            :value="get(item, valueKey)"
            :label="getLabel(item)"
            :disabled="item?.disabled"
            class="select-option"
          >
            <slot :option="item" />
          </ElOption>
        </ElOptionGroup>
      </template>
      <template v-else>
        <ElOption
          v-for="item in selectOptions"
          :key="get(item, valueKey)"
          :value="get(item, valueKey)"
          :label="getLabel(item)"
          :disabled="item?.disabled"
          class="select-option"
        >
          <slot :option="item" />
        </ElOption>
      </template>
      <ElOption
        v-if="selectOptions.length === 0 && !allowCreate && !loading"
        value="no-data"
        disabled
      >
        <div class="text-center">
          <span v-if="!loading">{{ $t('No data') }}</span>
          <span v-else>{{ $t('Fetching data...') }}</span>
        </div>
      </ElOption>
      <div
        v-if="pagination?.last_page > pagination.page"
        :class="{ loading }"
        class="flex w-full justify-center btn btn-link"
        @click="loadMore"
      >
        {{ $t('Load more') }}
      </div>
    </ElSelect>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, useAttrs, watch } from 'vue'
import { get } from 'lodash-es'
import { usePaginatedRequest } from '@/modules/common/composables/usePaginatedRequest'
import i18n from '@/i18n'
import { isUuid } from "@/modules/common/utils/stringUtils";

const props = defineProps({
  modelValue: {
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  url: {
    type: String,
    default: '',
  },
  urlParams: {
    type: Object,
    default: () => ({}),
  },
  transformData: {
    type: Function,
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  labelKey: {
    type: String,
    default: 'label',
  },
  labelFormatter: {
    type: Function,
  },
  hasGroups: {
    type: Boolean,
    default: false,
  },
  fetchAtStart: {
    type: Boolean,
    default: false,
  },
  selectFirst: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  createLabelPrefix: {
    type: String,
    default: i18n.t('Create new: '),
  },
  filterFunction: {
    type: Function,
  },
  dataLoading: Boolean,
})

const emit = defineEmits(['update:modelValue', 'update:fullValue'])

const select = ref()

const { data, loading, fetchData, pagination } = usePaginatedRequest({
  url: props.url,
  urlParams: props.urlParams,
  transformData: props.transformData,
  appendData: true,
})

if (props.fetchAtStart) {
  fetchData()
}

const popperClass = computed<string>(() => {
  let className = ''
  if (allowCreate.value) {
    className += 'allow-create'
  }
  if (attrs['popper-class']) {
    className += ` ${attrs['popper-class']}`
  }
  if (attrs.name) {
    className += ` ${attrs.name}`
  }
  return className
})

const selectOptions = computed(() => {
  if (props.url) {
    return filterOptions(data.value)
  }
  return filterOptions(props.options)
})

const isOptionInList = computed(() => {
  const fullValue = getFullValue(model.value)
  return typeof fullValue === 'object' && Object.keys(fullValue).length > 0
})

const isIdValue = computed(() => {
  return model.value && isUuid(model.value)
})

function filterOptions(options: any[]): any[] {
  if (props.filterFunction) {
    return options?.filter(option => props.filterFunction?.(option))
  }
  return options
}

const attrs = useAttrs()
const allowCreate = computed(() => {
  const allowCreate = attrs['allow-create']
  return allowCreate === '' || allowCreate === true
})

const isLoadingInitialData = computed(() => {
  return (props.dataLoading || loading.value) && selectOptions.value.length === 0
})

const placeholder = computed(() => {
  if (isLoadingInitialData.value) {
    return ''
  }
  return attrs.placeholder || ''
})

const model = computed({
  get() {
    if (isLoadingInitialData.value) {
      return ''
    }
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val, selectOptions.value)
    emitFullValueEvent(val)
  },
})

function emitFullValueEvent(val: any) {
  const fullValue = getFullValue(val)
  if (typeof fullValue !== 'object') {
    return null
  }
  emit('update:fullValue', fullValue)
}

function getFullValue(value: any) {
  let options = selectOptions.value
  if (props.hasGroups) {
    options = options.reduce((acc, group) => {
      return acc.concat(group.options)
    }, [])
  }
  return options.find((item) => {
    return get(item, props.valueKey) === value
  }) || value
}

watch(() => selectOptions.value, (val: any) => {
  const hasSelectedValue = props.multiple ? model.value.length : model.value
  if (!hasSelectedValue && props.selectFirst && val.length) {
    const firstOption = get(val[0], props.valueKey)
    model.value = props.multiple ? model.value?.push(firstOption) : firstOption
  }
})

async function loadMore() {
  pagination.page++
}

async function remoteMethod(search: string) {
  await fetchData({
    search,
  })
}

function onFocus() {
  if (!data.value?.length) {
    fetchData()
  }
}

function getLabel(item: any) {
  if (props.labelFormatter) {
    return props.labelFormatter(item)
  }
  return get(item, props.labelKey)
}

function focus() {
  select.value?.focus()
}

onMounted(() => {
  if (props.modelValue) {
    emitFullValueEvent(props.modelValue)
  }
})

defineExpose({
  focus,
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss">
.el-select .el-input {
  @apply input px-0 border-0;
}
.hide-input-value.base-select .el-input__inner {
  opacity: 0;
}
.allow-create .el-select-dropdown__item:not(.select-option) {
  &:before {
    @apply mr-1 text-base-content;
    content: 'Create new:';
  }
  span {
    quotes: initial;
  }
}
</style>
